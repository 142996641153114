import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Inject, HostListener, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { LandaService } from './../../core/services/landa.service';
import { DomSanitizer} from '@angular/platform-browser';

import { DOCUMENT, Location, LocationStrategy } from '@angular/common';
import Swal, { SweetAlertResult } from 'sweetalert2';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  title = 'Venturo';
  judul: any
  asal: any
  temp: any
  data: any
  total: any
  isView: boolean
  isLoading: any
  isOverdue: any
  isBelumlunas: any
  isUmurPiutang: any
  isProfit: boolean
  isRevenue: boolean
  isExpense: boolean
  isCashflow: any
  detailCashflow: any
  isNeraca: any
  isJurnalpenerimaan: any
  isJurnalpengeluaran: any
  detailJurnalpenerimaan: any
  detailJurnalpengeluaran: any
  isAssets: boolean
  isEquity: boolean
  isLiabilities: boolean
  isProfile: any
  isBukuBesar: any
  listData: any
  detail: any
  overdue: any
  overdueBelumLunas: any
  buku_besar: any
  opsi: any
  profit: any
  cashflow: any
  jurnal_penerimaan: any
  jurnal_pengeluaran: any
  hasilcashflow: any
  neraca: any
  posisi: any
  id_akun: any
  akun: any
  akun_nama: any
  akun_rek: any
  start_load: any
  total_jurnal: any
  umur_piutang_opsi: any
  data_repeat_umur_piutang: any
  isPeriode: any
  startDate: any
  endDate: any
  rowInvoiceId: any
  isShowInvoice: any
  urlInvoice: any
  jenisView: any
  listTutupBulan: any
  bulan = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des"
  ]
  month = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ]
  thisBulan: any
  thisMonth: any
  thisTahun: any
  current: any
  // topbar
  thisDay: any
  perusahaan: any
  val: any
  session: any
  thisYear: any

  // Loading
  loadingSaldo: any
  loadingOverdue: any
  loadingProfit: any
  loadingCashflow: any
  loadingNeraca: any
  loadingReminderInv: any

  // filter
  listCustomer: any
  listCustomerDefault: any
  filterOverdueTerpilih: null
  searchCust: any

  // filter tanggal periode
  isPickTgl: any
  periodeStart: any
  periodeEnd: any
  isHideSave: boolean

  // reminder invoice
  totalInvScheduled: any
  totalInvOverdue: any
  listInvScheduled: any
  listInvOverdue: any
  jumlahInvScheduled: any
  jumlahInvOverdue: any
  isReminderInv: boolean
  reminder_inv_opsi: any
  data_repeat_reminder_inv: any
  rowReminderInvId: any
  isShowReminderInv: boolean
  urlReminderInv: any

  akun_id_arus_kas: any

  dataTemp: any

  constructor(
    @Inject(DOCUMENT) private document: any,
    private location: LocationStrategy,
    private lokasi: Location,
    private router: Router,
    private landaService: LandaService,
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer
  ) {
    history.pushState(null, null, window.location.href);
    // cek jika tombol kembali diklik.
    this.location.onPopState(() => {
      this.router.navigateByUrl(this.router.url);
      this.lokasi.go(this.router.url);
      this.index()
    });
  }
  public daterange: any = {};
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
  };

  ngOnInit() {
    this.opsi = null
    this.isLoading = false
    this.start_load = true
    this.loadingSaldo = true
    this.loadingOverdue = true
    this.loadingReminderInv = true
    this.loadingProfit = true
    this.loadingCashflow = true
    this.loadingNeraca = true
    let d = new Date()
    this.thisDay = d.getDate()
    this.current = d.getMonth()
    this.thisBulan = this.bulan[this.current]
    this.thisMonth = this.month[this.current]
    this.thisYear = d.getFullYear()
    this.thisTahun = d.getFullYear()
    this.id_akun = 1
    this.searchCust = ''
    this.isPickTgl = false
    if (document.readyState !== 'complete')
      this.start_load = true
    this.landaService.DataGet('/site/session', '').subscribe((res: any) => {
      this.session = res.data
      this.perusahaan = res.data.perusahaan
      this.val = res.data.curr_perusahaan
      this.dataInit()
    })
  }
  getData(isReset = false, isReload = false, isDetail = false) {
    this.thisMonth = this.month[this.current]
    
    if (isReset == true) {
      this.isPeriode = false      
    }

    let params = {};
    if (this.isPeriode == true) {
      params = {
        key: this.val.key,
        is_periode: 1,
        start: this.startDate,
        end: this.endDate
      }
    } else {
      params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear
      }
    }

    this.landaService.DataGet('/dashboard/listAkun', params).subscribe((res: any) => {
      this.akun = res.data
    });
    this.isLoading = true
    this.landaService.DataGet('/dashboard/listTutupBulan', params).subscribe((res: any) => {
      this.listTutupBulan = res.data      
      this.isLoading = false
    });
    if (!isReload) {
      this.isLoading = true
      this.landaService.DataGet('/dashboard/saldo', params).subscribe((res: any) => {
        this.listData = res.data.data
        this.isLoading = false
      });
      this.isLoading = true
      this.landaService.DataGet('/dashboard/overdue', params).subscribe((res: any) => {
        this.overdue = res.data
        this.listCustomer = res.data.list_customer
        this.listCustomerDefault = res.data.list_customer
        this.filterOverdueTerpilih = null
        this.isLoading = false
      });
      this.isLoading = true
      this.landaService.DataGet('/dashboard/reminderInvoice', params).subscribe((res: any) => {
        this.totalInvScheduled = res.data.totalInvScheduled
        this.totalInvOverdue   = res.data.totalInvOverdue
        this.listInvScheduled  = res.data.listInvScheduled
        this.listInvOverdue    = res.data.listInvOverdue
        this.jumlahInvScheduled  = res.data.jumlahInvScheduled
        this.jumlahInvOverdue    = res.data.jumlahInvOverdue
        this.isLoading = false
      });
    }
    this.isLoading = true
    this.landaService.DataGet('/dashboard/profit', params).subscribe((res: any) => {
      this.profit = res.data
      this.isLoading = false
    });
    this.isLoading = true
    this.landaService.DataGet('/dashboard/cashflow', params).subscribe((res: any) => {
      this.cashflow = res.data
      this.isLoading = false
    });
    this.isLoading = true
    this.landaService.DataGet('/dashboard/jurnal_penerimaan', params).subscribe((res: any) => {
      this.jurnal_penerimaan = res.data
      this.isLoading = false
    });
    this.isLoading = true
    this.landaService.DataGet('/dashboard/jurnal_pengeluaran', params).subscribe((res: any) => {
      this.jurnal_pengeluaran = res.data
      this.isLoading = false
    });
    this.isLoading = true
    this.landaService.DataGet('/dashboard/neraca', params).subscribe((res: any) => {
      this.neraca = res.data
      this.isLoading = false
    });
    
    if (isDetail == true && this.isView == true) {
      this.view(this.dataTemp, true)
    }
  }
  index() {
    this.temp = null
    this.opsi = null
    this.isView = false
    this.isOverdue = false
    this.isBelumlunas = false
    this.isUmurPiutang = false
    this.isReminderInv = false
    this.isProfit = false
    this.isCashflow = false
    this.detailCashflow = false
    this.isJurnalpenerimaan = false
    this.isJurnalpengeluaran = false
    this.detailJurnalpenerimaan = false
    this.detailJurnalpengeluaran = false
    this.isNeraca = false
    this.isAssets = false
    this.isEquity = false
    this.isLiabilities = false
    this.isProfile = false
    this.isBukuBesar = false
    this.total_jurnal = 0    
    this.jenisView = this.jenisView != 'home' && this.jenisView != 'report' ? 'home' : this.jenisView
    
    if (this.posisi == 'profit')
      this.seeProfit()
    else if (this.posisi == 'cashflow')
      this.seeCashflow()
    else if (this.posisi == 'jurnal_penerimaan')
      this.seeJurnalpenerimaan()
    else if (this.posisi == 'jurnal_pengeluaran')
      this.seeJurnalpengeluaran()
    else if (this.posisi == 'neraca')
      this.seeNeraca()
    else {
      if (this.asal == 'umur_piutang') {
        this.seeUmurPiutang(this.umur_piutang_opsi)
      } else {
        this.getData(this.isPeriode == true ? false : true)
        this.onActivate()
      }
    }    
  }
  view(data, isReload = false) {
    this.onActivate()
    if (isReload == false) {
      this.posisi = 'saldo'
      this.judul = "Saldo Kas"
      this.isView = true
      this.dataTemp = data
    }
    let akun = data.nama.replace('a.n ', '')
    akun = akun.split(" - ")
    this.akun_nama = akun[1]
    this.akun_rek = akun[2]

    let params = {};
    if (this.isPeriode == true) {
      params = {
        key: this.val.key,
        is_periode: 1,
        start: this.startDate,
        end: this.endDate,
        m_akun_id: this.id_akun
      }
    } else {
      params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear,
        m_akun_id: this.id_akun
      }
    }

    this.isLoading = true
    this.landaService.DataGet('/dashboard/detail', params).subscribe((res: any) => {
      this.detail = res.data
      for (const [key, value] of Object.entries(this.akun)) {
        if (value['nama'] == data.nama) {
          for (const [kunci, baris] of Object.entries(this.detail.detail)) {
            if (value['id'] == baris['akun_id']) {
              this.id_akun = baris['akun_id']
              
              let params = {};
              if (this.isPeriode == true) {
                params = {
                  key: this.val.key,
                  is_periode: 1,
                  start: this.startDate,
                  end: this.endDate,
                  m_akun_id: this.id_akun
                }
              } else {
                params = {
                  key: this.val.key,
                  bulan: this.current + 1,
                  tahun: this.thisYear,
                  m_akun_id: this.id_akun
                }
              }
              this.isLoading = true
              this.landaService.DataGet('/dashboard/buku_besar', params).subscribe((res: any) => {
                this.buku_besar = res.data
                this.isLoading = false
              })
              break;
            }
          }
          break;
        }
      }
      this.isLoading = false
    });
  }
  seeOverdue() {
    this.onActivate()
    this.judul = "Pengingat Penagihan"
    this.isOverdue = true
    this.isBelumlunas = false
  }
  seeBelumlunas(asal) {
    this.onActivate()
    this.judul = "Penjualan Belum Lunas"
    this.isOverdue = false
    this.isBelumlunas = true
    this.asal = asal;
  }
  seeReminderInv(opsi) {
    this.onActivate()
    this.judul = "Reminder Invoices"
    this.isOverdue = false
    this.isReminderInv = true
    if (opsi == 'overdue') {
      this.data_repeat_reminder_inv = this.listInvOverdue
    }
    if (opsi == 'scheduled') {
      this.data_repeat_reminder_inv = this.listInvScheduled
    }
    this.reminder_inv_opsi = opsi
  }
  seeUmurPiutang(opsi) {
    this.onActivate()
    this.judul = "Piutang"
    this.isOverdue = false
    this.isUmurPiutang = true
    this.umur_piutang_opsi = opsi
    this.data_repeat_umur_piutang = this.overdue.umur_piutang[opsi].detail
    this.asal = 'dashboard'    
  }
  seeProfit() {
    this.onActivate()
    this.posisi = null
    this.judul = "Laba & Rugi"
    this.isRevenue = true
    this.isExpense = false
    this.isProfit = true
  }
  seeRevenue() {
    this.onActivate()
    this.posisi = null
    this.judul = "Laba & Rugi"
    this.isProfit = true
    this.isRevenue = true
    this.isExpense = false
  }
  seeExpense() {
    this.onActivate()
    this.posisi = null
    this.judul = "Laba & Rugi"
    this.isProfit = true
    this.isExpense = true
    this.isRevenue = false
  }
  seeCashflow() {
    this.onActivate()
    this.posisi = null
    this.judul = "Arus Kas"
    this.isCashflow = true
    this.isJurnalpenerimaan = false
    this.isJurnalpengeluaran = false
  }
  seeJurnalpenerimaan() {
    this.onActivate()
    this.posisi = null
    this.judul = "Jurnal Penerimaan Kas"
    this.isCashflow = false
    this.isJurnalpenerimaan = true
    this.isJurnalpengeluaran = false
  }
  seeJurnalpengeluaran() {
    this.onActivate()
    this.posisi = null
    this.judul = "Jurnal Pengeluaran Kas"
    this.isCashflow = false
    this.isJurnalpenerimaan = false
    this.isJurnalpengeluaran = true
  }

  seeNeraca() {
    this.onActivate()
    this.posisi = null
    this.judul = "Neraca"
    this.isNeraca = true
    this.isAssets = true
    this.isEquity = true
    this.isLiabilities = false
  }
  seeEquity() {
    this.onActivate()
    this.posisi = null
    this.judul = "Neraca"
    this.isNeraca = true
    this.isAssets = false
    this.isEquity = true
    this.isLiabilities = false
  }
  seeLiabilities() {
    this.onActivate()
    this.posisi = null
    this.judul = "Neraca"
    this.isNeraca = true
    this.isAssets = false
    this.isEquity = false
    this.isLiabilities = true
  }
  seeProfile() {
    this.onActivate()
    this.posisi = null
    this.judul = "Profile"
    this.isProfile = true
  }
  bukubesar(id, posisi, opsi = null) {
    this.opsi = opsi
    if(id == 132 || id == 130)
      this.opsi = 'akumulasi'
    else if(id == 88)
      this.opsi == 'prive'
    this.posisi = posisi
    this.isBukuBesar = true
    this.isProfit = false
    this.isCashflow = false
    this.isJurnalpenerimaan = false
    this.isJurnalpengeluaran = false
    this.isNeraca = false
    this.id_akun = id

    let params = {};
    if (this.isPeriode == true) {
      params = {
        key: this.val.key,
        is_periode: 1,
        start: this.startDate,
        end: this.endDate,
        m_akun_id: this.id_akun
      }
    } else {
      params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear,
        m_akun_id: this.id_akun
      }
    }

    this.isLoading = true
    this.landaService.DataGet('/dashboard/buku_besar', params).subscribe((res: any) => {
      this.buku_besar = res.data
      this.isLoading = false
    })
  }

  detailjurnal(akun, posisi, opsi = null) {
    this.onActivate()
    this.id_akun = akun.id
    this.temp = akun
    this.opsi = opsi
    this.posisi = posisi
    this.isCashflow = false
    this.detailCashflow = false
    this.isJurnalpenerimaan = false
    this.isJurnalpengeluaran = false
    this.hitungTotalJurnal()
    
    if (posisi == 'cashflow') {
      this.detailCashflow = true
    } else if (posisi == 'jurnal_penerimaan') {
      this.detailJurnalpenerimaan = true
      this.isJurnalpenerimaan = false
    } else {
      this.detailJurnalpengeluaran = true
      this.isJurnalpengeluaran = false
    }
  }

  nextMonth() {
    if (!this.isLoading) {
      var bulan_temp = this.current == 11 ? 0 : ++this.current
      var tahun_temp = bulan_temp == 0 ? ++this.thisYear : this.thisYear
      var params = {
        key: this.val.key,
        bulan: bulan_temp + 1,
        tahun: tahun_temp,
        m_akun_id: this.id_akun
      }
      
      this.current = params.bulan - 1
      this.thisYear = params.tahun
      this.thisMonth = this.month[this.current]
      this.isLoading = true
      if (this.isView == true || this.isOverdue == true || this.isBelumlunas == true || this.isUmurPiutang == true || 
        this.isProfit == true || this.isCashflow == true || this.detailCashflow == true || this.isJurnalpenerimaan == true ||
        this.isJurnalpengeluaran == true || this.detailJurnalpenerimaan == true || this.detailJurnalpengeluaran == true || 
        this.isNeraca == true || this.isProfile == true || this.isBukuBesar == true) {
        
          this.landaService.DataGet('/dashboard/detail', params).subscribe((res: any) => {
            this.detail = res.data

            this.landaService.DataGet('/dashboard/buku_besar', params).subscribe((res: any) => {
              this.buku_besar = res.data
            })
          });
    }
      this.landaService.DataGet('/dashboard/profit', params).subscribe((res: any) => {
        this.profit = res.data
      });
      this.landaService.DataGet('/dashboard/cashflow', params).subscribe((res: any) => {
        this.cashflow = res.data
      });
      this.landaService.DataGet('/dashboard/jurnal_penerimaan', params).subscribe((res: any) => {
        this.jurnal_penerimaan = res.data
      });
      this.landaService.DataGet('/dashboard/jurnal_pengeluaran', params).subscribe((res: any) => {
        this.jurnal_pengeluaran = res.data
        this.hitungTotalJurnal()
      });
      this.landaService.DataGet('/dashboard/neraca', params).subscribe((res: any) => {
        this.neraca = res.data
        this.isLoading = false
      });
    }
  }
  prevMonth() {
    if (!this.isLoading) {
      var bulan_temp = this.current == 0 ? 11 : --this.current
      var tahun_temp = bulan_temp == 11 ? --this.thisYear : this.thisYear
      var params = {
        key: this.val.key,
        bulan: bulan_temp + 1,
        tahun: tahun_temp,
        m_akun_id: this.id_akun
      }
      
      this.current = params.bulan - 1
      this.thisYear = params.tahun
      this.thisMonth = this.month[this.current]
      
      this.isLoading = true
      if (this.isView == true || this.isOverdue == true || this.isBelumlunas == true || this.isUmurPiutang == true || 
          this.isProfit == true || this.isCashflow == true || this.detailCashflow == true || this.isJurnalpenerimaan == true ||
          this.isJurnalpengeluaran == true || this.detailJurnalpenerimaan == true || this.detailJurnalpengeluaran == true || 
          this.isNeraca == true || this.isProfile == true || this.isBukuBesar == true) {
          
            this.landaService.DataGet('/dashboard/detail', params).subscribe((res: any) => {
              this.detail = res.data

              this.landaService.DataGet('/dashboard/buku_besar', params).subscribe((res: any) => {
                this.buku_besar = res.data
              })
            });
      }
      this.landaService.DataGet('/dashboard/profit', params).subscribe((res: any) => {
        this.profit = res.data
      });
      this.landaService.DataGet('/dashboard/cashflow', params).subscribe((res: any) => {
        this.cashflow = res.data
      });
      this.landaService.DataGet('/dashboard/jurnal_penerimaan', params).subscribe((res: any) => {
        this.jurnal_penerimaan = res.data
      });
      this.landaService.DataGet('/dashboard/jurnal_pengeluaran', params).subscribe((res: any) => {
        this.jurnal_pengeluaran = res.data
        this.hitungTotalJurnal()
      });
      this.landaService.DataGet('/dashboard/neraca', params).subscribe((res: any) => {
        this.neraca = res.data
        this.start_load = false
        this.isLoading = false
      });
    }
  }
  
  /**
   * filter periode start
   */
  pilihTgl() {
    if (!this.isLoading) {
      this.daterange.start = this.periodeStart;
      this.daterange.end = this.periodeEnd;
  
      this.isPickTgl = true;
    }
  }

  cancelPickTgl() {
    if (!this.isLoading) {
      this.isPickTgl = false;
    }
  }

  pilihPeriode() {
    var start = new Date(this.periodeStart);
    var end = new Date(this.periodeEnd);

    var params = {
      key: this.val.key,
      is_periode: 1,
      start: start.getDate() + '-' + (start.getMonth() + 1) + '-' + start.getFullYear(),
      end: end.getDate() + '-' + (end.getMonth() + 1) + '-' + end.getFullYear(),
      m_akun_id: this.id_akun
    }

    this.startDate = params.start
    this.endDate = params.end
    this.isPeriode = true;
    
    this.isLoading = true
    if (this.isView == true || this.isOverdue == true || this.isBelumlunas == true || this.isUmurPiutang == true || 
      this.isProfit == true || this.isCashflow == true || this.detailCashflow == true || this.isJurnalpenerimaan == true ||
      this.isJurnalpengeluaran == true || this.detailJurnalpenerimaan == true || this.detailJurnalpengeluaran == true || 
      this.isNeraca == true || this.isProfile == true || this.isBukuBesar == true) {
      
        this.landaService.DataGet('/dashboard/detail', params).subscribe((res: any) => {
          this.detail = res.data
          
          this.landaService.DataGet('/dashboard/buku_besar', params).subscribe((res: any) => {
            this.buku_besar = res.data
          })
        });
  }
    this.landaService.DataGet('/dashboard/profit', params).subscribe((res: any) => {
      this.profit = res.data
    });
    this.landaService.DataGet('/dashboard/cashflow', params).subscribe((res: any) => {
      this.cashflow = res.data
    });
    this.landaService.DataGet('/dashboard/jurnal_penerimaan', params).subscribe((res: any) => {
      this.jurnal_penerimaan = res.data
    });
    this.landaService.DataGet('/dashboard/jurnal_pengeluaran', params).subscribe((res: any) => {
      this.jurnal_pengeluaran = res.data
      this.hitungTotalJurnal()
    });
    this.landaService.DataGet('/dashboard/neraca', params).subscribe((res: any) => {
      this.neraca = res.data
      this.start_load = false
      this.isLoading = false
    });
  }
  
  selectedDate(value: any, datepicker?: any) { 
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
 
    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;

    this.periodeStart = value.start
    this.periodeEnd = value.end

    this.isPickTgl = false;
    this.isPeriode = true;
    
    this.pilihPeriode()
  }

  resetPeriodeFooter() {    
    if (!this.isLoading) {
      this.isPeriode = false
      this.getData(true)
    }
  }  
  /**
   * filter periode end
   */

  onActivate() {
    window.scroll(0, 0);
  }

  // topbar
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: 'sm' });
  }

  getPerusahaan() {
    this.landaService.DataGet('/site/getPerusahaan', '').subscribe((res: any) => {
      this.session = res.data
      this.perusahaan = res.data.user.perusahaan
      
    })
  }

  pilihPerusahaan(value = null, index: any) {
    this.val = value
    
    this.landaService.DataGet('/site/ubahSessionPerusahaan', {key: this.val.key, label: this.val.label}).subscribe((res: any) => {
      this.session = res.data
    });
    this.index()
  }

  /** 
   * Filter tanggal dan customer start
   */
  pilihFilterTanggal(sorting) {
    this.filterOverdueTerpilih = sorting
    let params = {};
    if (this.isPeriode == true) {
      params = {
        key: this.val.key,
        is_periode: 1,
        start: this.startDate,
        end: this.endDate,
        m_akun_id: this.id_akun
      }
    } else {
      params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear,
        sorting: sorting
      }
    }
    this.isLoading = true
    this.landaService.DataGet('/dashboard/overdue', params).subscribe((res: any) => {
      this.overdue = res.data
      this.isLoading = false
    });
  }

  pilihFilterCustomer(customer) {
    this.filterOverdueTerpilih = customer.nama
    let params = {};
    if (this.isPeriode == true) {
      params = {
        key: this.val.key,
        is_periode: 1,
        start: this.startDate,
        end: this.endDate,
        m_akun_id: this.id_akun
      }
    } else {
      params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear,
        customer: customer.nama
      }
    }
    this.isLoading = true
    this.landaService.DataGet('/dashboard/overdue', params).subscribe((res: any) => {
      this.overdue = res.data
      this.isLoading = false
    });
  }

  resetFilterOverdue() {
    this.filterOverdueTerpilih = null
    let params = {};
    if (this.isPeriode == true) {
      params = {
        key: this.val.key,
        is_periode: 1,
        start: this.startDate,
        end: this.endDate,
        m_akun_id: this.id_akun
      }
    } else {
      params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear
      }
    }
    this.isLoading = true
    this.landaService.DataGet('/dashboard/overdue', params).subscribe((res: any) => {
      this.overdue = res.data
      this.isLoading = false
    });
  }

  cariCustomer() {
    this.listCustomer = this.listCustomerDefault.filter(n => n.nama.toLowerCase().includes(this.searchCust.toLowerCase()))
  }
  /** 
   * Filter tanggal dan customer end
   */

  closeOneModal() {

    // get modal
    const modal = document.getElementById("modalPerusahaan");

    // change state like in hidden modal
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');

    // get modal backdrop
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove opened modal backdrop
    document.body.removeChild(modalBackdrops[0]);
  }

  logout() {
    this.landaService
      .DataGet('/site/logout', {})
      .subscribe((res: any) => {
        this.router.navigate(['/login']);
      });
  }

  displayCounter(count) {
  }
  bold(param) {
    var cari = param.search("<b>");
    if (cari >= 0) {
      return 'font-weight-bold'
    } else {
      return ''
    }
    
  }
  dataInit() {
    this.jenisView = this.jenisView != 'home' && this.jenisView != 'report' ? 'home' : this.jenisView
    this.thisMonth = this.month[this.current]
    let params = {
      key: this.val.key,
      bulan: this.current + 1,
      tahun: this.thisYear
    }
    this.landaService.DataGet('/dashboard/listAkun', params).subscribe((res: any) => {
      this.akun = res.data
    });
    this.landaService.DataGet('/dashboard/listTutupBulan', params).subscribe((res: any) => {
      this.listTutupBulan = res.data      
    });
    this.landaService.DataGet('/dashboard/saldo', params).subscribe((res: any) => {
      this.listData = res.data.data
      this.loadingSaldo = false
    });
    this.start_load = true
    this.landaService.DataGet('/dashboard/overdue', params).subscribe((res: any) => {
      this.overdue = res.data
      this.listCustomer = res.data.list_customer
      this.listCustomerDefault = res.data.list_customer
      this.filterOverdueTerpilih = null
      this.loadingOverdue = false
    });
    this.landaService.DataGet('/dashboard/reminderInvoice', params).subscribe((res: any) => {
      this.totalInvScheduled = res.data.totalInvScheduled
      this.totalInvOverdue   = res.data.totalInvOverdue
      this.listInvScheduled  = res.data.listInvScheduled
      this.listInvOverdue    = res.data.listInvOverdue
      this.jumlahInvScheduled  = res.data.jumlahInvScheduled
      this.jumlahInvOverdue    = res.data.jumlahInvOverdue
      this.loadingReminderInv = false
    });
    this.landaService.DataGet('/dashboard/profit', params).subscribe((res: any) => {
      this.profit = res.data
      this.loadingProfit = false
    });
    this.landaService.DataGet('/dashboard/cashflow', params).subscribe((res: any) => {
      this.cashflow = res.data
    });
    this.landaService.DataGet('/dashboard/jurnal_penerimaan', params).subscribe((res: any) => {
      this.jurnal_penerimaan = res.data
    });
    this.landaService.DataGet('/dashboard/jurnal_pengeluaran', params).subscribe((res: any) => {
      this.jurnal_pengeluaran = res.data
      this.loadingCashflow = false
    });
    this.landaService.DataGet('/dashboard/neraca', params).subscribe((res: any) => {
      this.neraca = res.data
      this.loadingNeraca = false
    });
  }
  objLength(arr){
    return Object.keys(arr).length
  }
  hitungTotalJurnal(){
    let total = 0
    let total1 = 0
    let total2 = 0
    let total3 = 0
    this.total_jurnal = 0
    let penerimaan_debit, penerimaan_kredit, pengeluaran_debit, pengeluaran_kredit = []
    penerimaan_debit = this.jurnal_penerimaan.data.total_akun.debit
    penerimaan_kredit = this.jurnal_penerimaan.data.total_akun.kredit
    pengeluaran_debit = this.jurnal_pengeluaran.data.total_akun.debit
    pengeluaran_kredit = this.jurnal_pengeluaran.data.total_akun.kredit
    
    penerimaan_debit.forEach((val, key) => {
      if(val.akun.id == this.temp.id){
        total = total + val.total
      }
    })
    penerimaan_kredit.forEach((val, key) => {
      if(val.akun.id == this.temp.id){
        total1 = total1 + val.total
      }
    })
    pengeluaran_debit.forEach((val, key) => {
      if(val.akun.id == this.temp.id){
        total2 = total2 + val.total
      }
    })
    pengeluaran_kredit.forEach((val, key) => {
      if(val.akun.id == this.temp.id){
        total3 = val.total
      }
    })
    let temp = (total3+total1)-(total+total2)
    this.total_jurnal = temp < 0 ? temp*(-1) : temp
  }
  asIsOrder(a, b) {
    return 1;
  }

  /**
   * Invoices start
   */
  printInvoices(row) {
    this.rowInvoiceId = row.pos_bayar_piutang_id
    this.isShowInvoice = true
    var url = "https://accsystems.landa.co.id/api/t_bayar_piutang_v2/print/bukti/" + row.pos_bayar_piutang_id + "?config=" + this.val.key
    this.urlInvoice = this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  }
  hideInvoices(row) {
    this.rowInvoiceId = ""
    this.isShowInvoice = false
    this.urlInvoice = ""
  }
  /**
   * Invoices end
   */

  /**
   * Invoices draft start
   */
  printReminderInv(row) {    
    this.rowReminderInvId = row.id
    this.isShowReminderInv = true
    var url = "https://accsystems.landa.co.id/api/t_penjualan_v2/print?id=" + row.id + "&config=" + this.val.key
    this.urlReminderInv = this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  }
  hideReminderInv(row) {
    this.rowReminderInvId = ""
    this.isShowReminderInv = false
    this.urlReminderInv = ""
  }
  /**
   * Invoices draft end
   */
  
  /**
   * pindah menu start
   */
  pindahMenu(param) {
    this.jenisView = param
    this.onActivate()
  }
  /**
   * pindah menu end
   */

  /**
   * tutup bulan start
   */
  getListTutupBulan() {
    var params = {
      key: this.val.key
    }
    this.landaService.DataGet('/dashboard/listTutupBulan', params).subscribe((res: any) => {
      this.listTutupBulan = res.data      
    });
  }
  tutupBulan() {
    this.isHideSave = true
    var params = {
      key: this.val.key,
      bulan: this.current + 1,
      tahun: this.thisYear,
      id_last: this.listTutupBulan[this.thisYear][this.current + 1].id_last
    }
    this.landaService.DataGet('/dashboard/tutupBulan', params).subscribe((res: any) => {
      if (res.status_code == 200) {
        this.getListTutupBulan()
      } else {
        Swal.fire("Gagal", res.errors[0], "error");
      }
      this.isHideSave = false
    });
  }
  hapusTutupBulan() {
    this.isHideSave = true
    var params = {
      key: this.val.key,
      bulan: this.current + 1,
      tahun: this.thisYear,
      id: this.listTutupBulan[this.thisYear][this.current + 1].tutup_buku_id
    }
    this.landaService.DataGet('/dashboard/hapusTutupBulan', params).subscribe((res: any) => {
      if (res.status_code == 200) {
        this.getListTutupBulan()
      } else {
        Swal.fire("Gagal", res.errors[0], "error");
      }
      this.isHideSave = false
    });
  }
  /**
   * tutup bulan end
   */

  /**
   * Update arus kas akun
   */
  setAkunIdArusKas(akun_id) {
    this.akun_id_arus_kas = akun_id
  }
  updateAkunArusKas(tipe_arus) {
    var params = {
      key: this.val.key,
      id: this.akun_id_arus_kas,
      tipe_arus: tipe_arus
    }
    this.landaService.DataGet('/dashboard/updateArusKas', params).subscribe((res: any) => {
      if (res.status_code == 200) {
        this.akun_id_arus_kas = null
        this.getData(false, true, true);
      } else {
        Swal.fire("Gagal", res.errors[0], "error");
      }
    });
  }
  /**
   * Update arus kas akun end
   */
}
