<nav class="navbar navbar-expand-lg navbar-dark bg-biru d-flex justify-content-between {{jenisView == 'home' ? 'mb-4' : ''}}"
    *ngIf="!isView && !isOverdue && !isBelumlunas && !isReminderInv && !isUmurPiutang && !isProfit && !isCashflow && !detailCashflow && !isJurnalpenerimaan &&
        !isJurnalpengeluaran && !detailJurnalpenerimaan && !detailJurnalpengeluaran && !isNeraca && !isProfile && !isBukuBesar;
        else topbardetail">
    <div style="width: 60%">
        <div role="button">
            <h5 class="mt-2 mr-2 text-white font-weight-bold" (click)="openVerticallyCentered(content); getPerusahaan()" role="button">
                <span *ngIf="val != undefined">
                    {{ val.label }}
                </span>
                <fa-icon [icon]="['fas', 'angle-down']"></fa-icon>
            </h5>
        </div>
    </div>
    <div style="width: 25%; text-align: right;vertical-align: middle;">
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512" (click)="getData(false, true)" class="mr-1" style="fill: #ffffff; cursor: pointer;" [ngClass]="isLoading ? 'd-none' : ''">
            <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
        </svg>
    </div>
    <div style="width: 15%; text-align: right;vertical-align: middle;">
        <div class="nav-item dropdown">
            <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img class="img-circle rounded-circle mt-1" style="width: 35px" src="assets/images/users/user-default.png" alt="" />
            </a>
            <div class="dropdown-menu dropdown-menu-right shadow-sm" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" (click)="logout()" role="button">Logout</a>
            </div>
        </div>
    </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark bg-footer"
        *ngIf="!isView && !isOverdue && !isBelumlunas && !isReminderInv && !isUmurPiutang && !isProfit && !isCashflow && !detailCashflow && !isJurnalpenerimaan &&
        !isJurnalpengeluaran && !detailJurnalpenerimaan && !detailJurnalpengeluaran && !isNeraca && !isProfile && !isBukuBesar && jenisView == 'report'">
    <div class="container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div>
</nav>
<!-- Modal -->
<ng-template #content let-modal let-c="close">
    <div class="modal-body container px-3">
        <h5 class="modal-title mb-3">Pindah Perusahaan</h5>
        <div class="mb-n3">
            <ul *ngFor="let row of perusahaan; let i = index" class="list-group mx-n3" (click)="pilihPerusahaan(row, i); c('close')" role="button">
                <li class="list-group-item rounded-0 text-biru {{
                        row.label == val.label ? 'bg-footer' : ''
                    }}">
                    <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: small"></fa-icon>
                    {{ row.label }}
                </li>
            </ul>
        </div>
    </div>
</ng-template>
<ng-template #topbardetail>
    <nav class="navbar navbar-expand-lg navbar-dark mb-4 bg-biru justify-content-between text-white"
        style="position: fixed; top: 0; width: 576px; max-width: 576px; z-index: 1000;">
        <div role="button">
            <h5 class="mt-2 mr-2 font-weight-bold" (click)="index()" role="button">
                <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
                <span class="ml-2">{{ judul }}</span>
            </h5>
        </div>
        <div class="row">
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512" (click)="getData(false, true, true)" style="fill: #ffffff; cursor: pointer; margin-right: 12px;" [ngClass]="isLoading || isBelumlunas || isOverdue ? 'd-none' : ''">
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
            <div class="nav-item dropdown" *ngIf="isOverdue || isBelumlunas">
                <a class="nav-link font-weight-bold" href="#" id="navbarDropdownFilter" role="button" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false" style="color: white;">
                    <span class="mr-2">Filter</span>
                    <fa-icon [icon]="['fas', 'angle-down']"></fa-icon>
                </a>
                <div class="dropdown-menu dropdown-menu-right shadow-sm" aria-labelledby="navbarDropdownFilter">
                    <a class="dropdown-item" (click)="openVerticallyCentered(filterClient)" role="button">Client</a>
                    <a class="dropdown-item" (click)="openVerticallyCentered(filterTanggal)" role="button">Tanggal</a>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style="margin-top: 12px; margin-right: 12px; cursor: pointer;" (click)="resetFilterOverdue()" *ngIf="isOverdue || isBelumlunas">
                <style>svg{fill:#ffffff}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
    </nav>
    <div style="margin-top: 5rem"></div>
</ng-template>
<!-- Modal Client -->
<ng-template #filterClient let-modal let-c="close">
    <div class="modal-body container px-3" style="height: 500px;">
        <form>
            <div class="form-group">
                <label for="searchCust" class="text-biru">Cari Client</label>
                <input type="text" [(ngModel)]="searchCust" class="form-control" name="searchCust" id="searchCust"
                (keyup)="cariCustomer()">
            </div>
        </form>
        <div class="mb-n3" style="overflow-y: auto; overflow-x: hidden; height: 350px;">
            <ul *ngFor="let row of listCustomer;" class="list-group mx-n3" (click)="pilihFilterCustomer(row); c('close')" role="button">
                <li class="list-group-item rounded-0 text-biru {{row.nama == filterOverdueTerpilih ? 'bg-footer' : ''}}">
                    {{ row.nama }}
                </li>
            </ul>
        </div>
    </div>
</ng-template>
<!-- Modal Tanggal -->
<ng-template #filterTanggal let-modal let-c="close">
    <div class="modal-body container px-3">
        <h5 class="modal-title mb-3">Urutkan Tanggal</h5>
        <div class="mb-n3">
            <ul class="list-group mx-n3" role="button">
                <li class="list-group-item rounded-0 text-biru {{
                    filterOverdueTerpilih == 'DESC' ? 'bg-footer' : ''
                }}" (click)="pilihFilterTanggal('DESC'); c('close')">
                    Terbaru - Terlama
                </li>
                <li class="list-group-item rounded-0 text-biru {{
                    filterOverdueTerpilih == 'ASC' ? 'bg-footer' : ''
                }}" (click)="pilihFilterTanggal('ASC'); c('close')">
                    Terlama - Terbaru
                </li>
            </ul>
        </div>
    </div>
</ng-template>
<!-- Modal Arus Kas -->
<ng-template #modalArusKas let-modal let-c="close">
    <div class="modal-header" style="background-color: #009aac; color: white;">
        <h5 class="mb-0">Pilih Arus Kas</h5>
    </div>
    <div class="modal-body container px-3 pt-0">
        <div class="mb-n3">
            <ul class="list-group mx-n3" role="button">
                <li class="list-group-item rounded-0" (click)="updateAkunArusKas('Aktivitas Operasi'); c('close');">
                    Aktifitas Operasi
                </li>
                <li class="list-group-item rounded-0" (click)="updateAkunArusKas('Investasi'); c('close');">
                    Investasi
                </li>
                <li class="list-group-item rounded-0" (click)="updateAkunArusKas('Pendanaan'); c('close');">
                    Pendanaan
                </li>
            </ul>
        </div>
    </div>
</ng-template>
<!-- Body -->
<div *ngIf="!isView && !isOverdue && !isBelumlunas && !isReminderInv && !isUmurPiutang && !isProfit && !isCashflow && !detailCashflow && !isJurnalpenerimaan &&
    !isJurnalpengeluaran && !detailJurnalpenerimaan && !detailJurnalpengeluaran && !isNeraca && !isProfile && !isBukuBesar"
    style="margin-bottom: 5rem">
    <!-- Saldo -->
    <div class="container-fluid mb-3" *ngIf="jenisView == 'home'">
        <div class="card shadow-sm">
            <div class="table-responsive-sm">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="align-middle" scope="col" style="width: 50%">
                                Saldo Kas & Bank
                            </th>
                            <th class="align-middle text-right" scope="col" style="width: 50%">
                                Per {{ thisDay }} {{ thisBulan }} {{ thisTahun }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="loadingSaldo">
                            <td colspan="2">
                                <div class="d-flex justify-content-center">
                                    <small class="spinner-border spinner-border-sm text-info my-5" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <ng-container *ngIf="!loadingSaldo">
                            <tr *ngFor="let val of listData.detail" (click)="view(val)" class="{{ val.total == 0 ? 'd-none' : '' }}" role="button">
                                <td scope="row" *ngIf="!loadingSaldo">{{ val.nama }}</td>
                                <td class="text-right" *ngIf="!loadingSaldo">
                                    {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot>
                        <tr style="vertical-align: middle">
                            <th class="align-middle">TOTAL</th>
                            <th class="align-middle text-right">
                                <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="loadingSaldo">
                                    <span class="sr-only">Loading...</span>
                                </small>
                                <span *ngIf="!loadingSaldo">
                                    {{ listData.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                </span>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
    <!-- Sales Reminder Invoice -->
    <div *ngIf="!loadingSaldo && jenisView == 'home'">
        <div class="card mt-3 mx-3 shadow-sm">
            <div class="card-header font-weight-bold" style="background-color: #fafdff">
                Reminder Invoices
            </div>
            <div class="card-body pr-0 pl-0">
                <div class="text-center">
                    <small class="spinner-border spinner-border-sm text-info mb-3 my-4" *ngIf="isLoading">
                        <span class="sr-only">Loading...</span>
                    </small>
                </div>
                <div *ngIf="!loadingReminderInv && !isLoading">
                    <table style="width: 100%;">
                        <tr>
                            <td class="text-center" (click)="seeReminderInv('overdue')"
                                style="cursor: pointer;background-color: #ffffff; width: 50%;border-right: 0.5px solid #c4c4c4;">
                                Overdue
                                <br>
                                <span class="text-danger">
                                    <b>
                                        {{ totalInvOverdue | currency: "Rp. ":"symbol":"1.0-0" }}
                                    </b>
                                    <span *ngIf="jumlahInvOverdue > 0">
                                        &nbsp;({{jumlahInvOverdue}})
                                    </span>
                                </span>
                            </td>
                            <td class="text-center" (click)="seeReminderInv('scheduled')"
                                style="cursor: pointer;background-color: #ffffff; width: 50%;">
                                Scheduled
                                <br>
                                <span class="text-success">
                                    <b>
                                        {{ totalInvScheduled | currency: "Rp. ":"symbol":"1.0-0" }}
                                    </b>
                                    <span *ngIf="jumlahInvScheduled > 0">
                                        &nbsp;({{jumlahInvScheduled}})
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- Sales Overdue -->
    <div *ngIf="!loadingSaldo && jenisView == 'home'">
        <div class="card mt-3 mx-3 shadow-sm">
            <div class="card-header font-weight-bold" style="background-color: #fafdff">
                Piutang
            </div>
            <div class="card-body pr-0 pl-0">
                <div class="text-center">
                    <small class="spinner-border spinner-border-sm text-info mb-3 my-4" *ngIf="loadingOverdue || isLoading">
                        <span class="sr-only">Loading...</span>
                    </small>
                </div>
                <div *ngIf="!loadingOverdue && !isLoading">
                    <table style="width: 100%;">
                        <tr class="text-center" style="height: 65px;">
                            <td class="text-center" colspan="3" (click)="seeBelumlunas('dashboard')"
                                style="cursor:pointer;">
                                <h4 class="font-weight-bold m-0">
                                    <b>{{ overdue.total_belumlunas | currency: "Rp. ":"symbol":"1.0-0" }}</b>
                                </h4>
                                {{ overdue.jumlah_belumlunas }} Belum Bayar
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center" (click)="seeUmurPiutang('kurang30')"
                                style="zoom: 85%; cursor: pointer;background-color: #ffffff; width: 33.3%;border-right: 0.5px solid #c4c4c4;">
                                < 30 Hari
                                <br>
                                <span class="text-success">
                                    <b>
                                        {{ overdue.umur_piutang.kurang30.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                    </b>
                                    <span *ngIf="overdue.umur_piutang.jumlahKurang30 > 0">
                                        &nbsp;({{overdue.umur_piutang.jumlahKurang30}})
                                    </span>
                                </span>
                            </td>
                            <td class="text-center" (click)="seeUmurPiutang('kurang60')"
                                style="zoom: 85%; cursor: pointer;background-color: #ffffff; width: 33.3%;border-right: 0.5px solid #c4c4c4;">
                                31-60 Hari
                                <br>
                                <span class="text-warning">
                                    <b>
                                        {{ overdue.umur_piutang.kurang60.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                    </b>
                                    <span *ngIf="overdue.umur_piutang.jumlahKurang60 > 0">
                                        &nbsp;({{overdue.umur_piutang.jumlahKurang60}})
                                    </span>
                                </span>
                            </td>
                            <td class="text-center" (click)="seeUmurPiutang('lebih60')"
                                style="zoom: 85%; cursor: pointer;background-color: #ffffff; width: 33.3%;">
                                > 60 Hari
                                <br>
                                <span class="text-orange">
                                    <b>
                                        {{ overdue.umur_piutang.lebih60.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                    </b>
                                    <span *ngIf="overdue.umur_piutang.jumlahLebih60 > 0">
                                        &nbsp;({{overdue.umur_piutang.jumlahLebih60}})
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- <div *ngIf="!loadingOverdue && !isLoading">
                    <div class="row" (click)="seeOverdue()" role="button">
                        <div class="col mt-2">
                            <span class="text-nowrap">
                                {{ overdue.pengingat.totalItems }}
                            </span>
                            <br />
                            <small class="text-secondary">Pengingat Penagihan</small>
                        </div>
                        <div style="border-left: 0.5px solid #c4c4c4; height: 50px" class="mt-2"></div>
                        <div class="col mt-2">
                            <small class="text-secondary">Total</small>
                            <br />
                            <span class="text-nowrap">
                                {{
                                    overdue.pengingat.total | currency: "Rp. ":"symbol":"1.0-0"
                                }}
                            </span>
                        </div>
                    </div>
                </div> -->
                <!-- <div style="border-top: 0.5px solid #c4c4c4; width: 100%" *ngIf="!loadingOverdue && !isLoading"></div> -->
                <!-- <div *ngIf="!loadingOverdue && !isLoading">
                    <div class="row" (click)="seeBelumlunas()" role="button">
                        <div class="col mt-2">
                            <span class="text-nowrap">
                                {{ overdue.jumlah_belumlunas }}
                            </span>
                            <br />
                            <small class="text-secondary">Belum Bayar</small>
                        </div>
                        <div style="border-left: 0.5px solid #c4c4c4; height: 60px" class=""></div>
                        <div class="col mt-2">
                            <small class="text-secondary">Total</small>
                            <br />
                            <span class="text-nowrap">
                                {{overdue.total_belumlunas | currency: "Rp. ":"symbol":"1.0-0"}}
                            </span>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- Profit & Loss -->
    <div *ngIf="!loadingOverdue && !loadingSaldo && jenisView == 'report'">
        <div class="card mt-3 mx-3 shadow-sm">
            <div class="card-header font-weight-bold" style="background-color: #fafdff">
                Laba & Rugi
            </div>
            <div class="card-body" role="button" (click)="seeProfit()" role="button">
                <div class="text-center">
                    <small class="spinner-border spinner-border-sm text-info mb-3 my-4" *ngIf="loadingProfit">
                        <span class="sr-only">Loading...</span>
                    </small>
                    <span class="text-secondary" *ngIf="profit.data.total > 0">Laba Bulan Ini</span>
                    <span class="text-secondary" *ngIf="profit.data.total < 0">Rugi Bulan Ini</span>
                    <div class="mt-1 mb-n2 {{ profit.data.total < 0 ? 'text-orange' : 'text-success' }}">
                        <div class="mx-auto">
                            <small class="spinner-border spinner-border-sm text-info mb-3" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                        </div>
                        <p *ngIf="!loadingProfit && !isLoading">
                            <span style="font-size: 1.5rem; font-weight: bold; vertical-align: middle;">
                                {{ profit.data.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                            <span style="font-size: 1rem; vertical-align: middle;">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" style="fill: #28a745; margin-top: 5px;" *ngIf="profit.data.total > 0">
                                    <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z">
                                    </path>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" style="fill: #dd5e5d; margin-bottom: 12px;" *ngIf="profit.data.total < 0">
                                    <path d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/>
                                </svg>
                                {{ profit.data.persentase }}%
                            </span>
                        </p>
                        <!-- <h4 class="font-weight-bold" *ngIf="!loadingProfit && !isLoading" style="margin-bottom: 0px;">
                            {{ profit.data.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </h4> -->
                        <!-- <h5 class="font-weight-bold" *ngIf="!loadingProfit && !isLoading">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" style="fill: #28a745; margin-top: 5px;" *ngIf="profit.data.total > 0">
                                <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z">
                                </path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" style="fill: #dd5e5d; margin-bottom: 12px;" *ngIf="profit.data.total < 0">
                                <path d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/>
                            </svg>
                            {{ profit.data.persentase }}%
                        </h5> -->
                    </div>
                    <small class="text-secondary" style="font-size: smaller">
                        Lihat Detail
                    </small>
                    <div class="row pt-2 mb-n2 mx-n4" *ngIf="!loadingProfit || !isLoading">
                        <div class="col text-center mt-2">
                            <span class="text-secondary">Pendapatan</span>
                            <br />
                            <div class="mb-0">
                                <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="loadingProfit || isLoading">
                                    <span class="sr-only">Loading...</span>
                                </small>
                                <div *ngIf="!loadingProfit && !isLoading" class="text-nowrap text-success">
                                    <!-- {{ profit.detail.PENDAPATAN.total +
                                    profit.detail.PENDAPATAN_DILUAR_USAHA.total
                                    | currency: "Rp. ":"symbol":"1.0-0" }} -->
                                    {{ profit.data.total_pendapatan | currency: "Rp. ":"symbol":"1.0-0" }}
                                </div>
                            </div>
                        </div>
                        <div style="border-left: 0.5px solid #c4c4c4; height: 50px" class="mt-2"></div>
                        <div class="col text-center mt-2">
                            <span class="text-secondary">Biaya</span>
                            <br />
                            <div class="mb-0">
                                <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="loadingProfit || isLoading">
                                    <span class="sr-only">Loading...</span>
                                </small>
                                <div *ngIf="!loadingProfit && !isLoading" class="text-nowrap text-danger">
                                    <!-- {{ profit.detail.BEBAN.total +
                                        profit.detail.BEBAN_DILUAR_USAHA.total
                                        | currency: "Rp. ":"symbol":"1.0-0" }} -->
                                    {{ profit.data.total_beban | currency: "Rp. ":"symbol":"1.0-0" }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Cashflow -->
    <div *ngIf="!loadingOverdue && !loadingProfit && !loadingSaldo && jenisView == 'report'">
        <div class="card mt-3 mx-3 shadow-sm">
            <div class="card-header font-weight-bold" style="background-color: #fafdff">
                Arus Kas
            </div>
            <div class="card-body" (click)="seeCashflow()" role="button">
                <div class="text-center">
                    <small class="spinner-border spinner-border-sm text-info mb-3 my-4" role="status" *ngIf="loadingCashflow">
                        <span class="sr-only">Loading...</span>
                    </small>
                    <div *ngIf="!loadingCashflow">
                        <span class="text-secondary">
                            {{ cashflow.data.saldo_biaya >= 0 ? "Kenaikan" : "Penurunan" }}
                            Kas
                        </span>
                        <div class="mt-1 mb-n2">
                            <small class="spinner-border spinner-border-sm text-info mb-3" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <h4 class="font-weight-bold
                                {{
                                cashflow.data.saldo_biaya >= 0
                                    ? 'text-success'
                                    : 'text-orange'
                                }}" *ngIf="!loadingCashflow && !isLoading">
                                {{
                                    cashflow.data.saldo_biaya | currency: "Rp. ":"symbol":"1.0-0"
                                }}
                            </h4>
                        </div>
                        <small class="text-secondary" style="font-size: smaller">
                            Lihat Detail
                        </small>
                    </div>
                    <div class="row pt-2 mb-n2 mx-n4" *ngIf="!loadingCashflow">
                        <div class="col text-center mt-2" (click)="seeJurnalpenerimaan()" role="button">
                            <span class="text-secondary text-nowrap">Jurnal Penerimaan</span>
                            <br />
                            <div class="mb-0">
                                <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="loadingCashflow || isLoading">
                                    <span class="sr-only">Loading...</span>
                                </small>
                                <div *ngIf="!loadingCashflow && !isLoading" class="text-nowrap text-success">
                                    {{
                                        jurnal_penerimaan.data.total.debit
                                        | currency: "Rp. ":"symbol":"1.0-0"
                                    }}
                                </div>
                            </div>
                            <small class="text-secondary" style="font-size: smaller">
                                Lihat Detail
                            </small>
                        </div>
                        <div style="border-left: 0.5px solid #c4c4c4; height: 50px" class="mt-2"></div>
                        <div class="col text-center mt-2" (click)="seeJurnalpengeluaran()" role="button">
                            <span class="text-secondary text-nowrap">Jurnal Pengeluaran</span>
                            <br />
                            <div class="mb-0">
                                <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="loadingCashflow || isLoading">
                                    <span class="sr-only">Loading...</span>
                                </small>
                                <div *ngIf="!loadingCashflow && !isLoading" class="text-nowrap text-danger">
                                    {{
                                        jurnal_pengeluaran.data.total.kredit
                                        | currency: "Rp. ":"symbol":"1.0-0"
                                    }}
                                </div>
                            </div>
                            <small class="text-secondary" style="font-size: smaller">
                                Lihat Detail
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Neraca -->
    <div *ngIf="!loadingOverdue && !loadingProfit && !loadingSaldo && !loadingCashflow && jenisView == 'report'">
        <div class="card mt-3 mx-3 shadow-sm">
            <div class="card-header font-weight-bold" style="background-color: #fafdff">
                Neraca
            </div>
            <div class="card-body" (click)="seeNeraca()" role="button">
                <div class="text-center">
                    <div class="mt-1 mb-n2">
                        <span class="text-secondary">Harta</span>
                        <br />
                        <div class="text-center mb-n2" *ngIf="loadingNeraca || isLoading">
                            <small class="spinner-border spinner-border-sm text-info mb-3 my-4" role="status" *ngIf="loadingNeraca">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <small class="spinner-border spinner-border-sm text-info mb-3" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                        </div>
                        <h4 class="font-weight-bold
                        {{
                neraca.modelHarta.total.total < 0
                  ? 'text-orange'
                  : 'text-success'
              }}" *ngIf="!loadingNeraca && !isLoading">
                            {{ neraca.modelHarta.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </h4>
                    </div>
                    <small class="text-secondary" style="font-size: smaller">
                        Lihat Detail
                    </small>
                    <div class="row pt-2 mb-n2 mx-n4" *ngIf="!loadingNeraca || !isLoading">
                        <div class="col text-center mt-2">
                            <span class="text-secondary">Kewajiban</span>
                            <br />
                            <div class="mb-n4">
                                <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="loadingNeraca || isLoading">
                                    <span class="sr-only">Loading...</span>
                                </small>
                                <div *ngIf="!loadingNeraca && !isLoading" class="text-nowrap">
                                    {{
                    neraca.modelKewajiban.total
                      | currency: "Rp. ":"symbol":"1.0-0"
                  }}
                                </div>
                            </div>
                        </div>
                        <div style="border-left: 0.5px solid #c4c4c4; height: 50px" class="mt-2"></div>
                        <div class="col text-center mt-2">
                            <span class="text-secondary">Modal</span>
                            <br />
                            <div class="mb-n4">
                                <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="loadingNeraca || isLoading">
                                    <span class="sr-only">Loading...</span>
                                </small>
                                <div *ngIf="!loadingNeraca && !isLoading" class="text-nowrap">
                                    {{
                    neraca.modelModal.total | currency: "Rp. ":"symbol":"1.0-0"
                  }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Keterangan Tutup Bulan -->
    <div class="text-center mt-4">
        <span>&nbsp;</span>
    </div>
</div>

<!-- Detail -->
<!-- Detail Saldo -->
<div class="text-center" *ngIf="isView" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
      padding-top: 1.5rem;
      padding-bottom: 0.8rem;
      margin-top: -2rem;
      background-color: #7cc774;
    ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <div class="navbar navbar-expand-lg navbar-dark bg-footer container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div>
    <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </small>
    <div *ngIf="!isLoading">
        <div *ngFor="let row of buku_besar.detail | keyvalue: asIsOrder">
            <div class="container bg-footer" style="border-bottom: 0.3px solid #8b8b8b">
                <div class="form-group row bg-light py-2" style="border-bottom: 0.3px solid #8b8b8b">
                    <span class="
                            col col-form-label
                            text-left
                            font-weight-bold
                            align-self-center
                        ">
                        {{ row.value.akun }}
                    </span>
                </div>
                <div class="bg-footer">
                    <div class="form-group row">
                        <span class="col-4 col-form-label text-left font-weight-bold">Saldo Awal</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label text-right font-weight-bold">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading">
                                {{ row.value.saldo_awal | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                    <div class="form-group row">
                        <span class="col-4 col-form-label text-left">Mutasi Kredit</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label text-right">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading && row.value.total_kredit <= 0">-</div>
                            <div *ngIf="!isLoading && row.value.total_kredit > 0" class="text-danger">
                                {{ row.value.total_kredit | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                    <div class="form-group row">
                        <span class="col-4 col-form-label py-n5 text-left">Mutasi Debit</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label py-n5 text-right">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading && row.value.total_debit <= 0">-</div>
                            <div *ngIf="!isLoading && row.value.total_debit > 0" class="text-success">
                                {{ row.value.total_debit | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                    <div class="form-group row">
                        <span class="col-4 col-form-label py-n5 text-left font-weight-bold">Saldo Akhir</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label py-n5 text-right font-weight-bold">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading">
                                {{ row.value.total_saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                </div>
                <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
                    <span class="sr-only">Loading...</span>
                </small>
                <div *ngIf="!isLoading">
                    <ul class="list-group" *ngFor="let baris of buku_besar.detail[row.key].detail | keyvalue: asIsOrder">
                        <li class="list-group-item rounded-0 align-items-center mx-n3">
                            <div class="row">
                                <div class="col-7 text-left">
                                    <small>
                                        <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: small" class="mr-1 text-biru"></fa-icon>
                                        {{ baris.value.tanggal | date: "dd MMM yyyy" }}
                                    </small>
                                    <br />
                                    <small [innerHTML]="baris.value.kode"></small>
                                    <br />
                                    <span [innerHTML]="baris.value.keterangan"></span>
                                    <br />
                                </div>
                                <div class="col-5 text-right">
                                    <span class="text-danger font-weight-bold text-nowrap" *ngIf="baris.value.tipe == 'kredit'">
                                        {{
                                            baris.value.kredit | currency: "Rp. ":"symbol":"1.0-0"
                                        }}</span>
                                    <span class="text-success font-weight-bold text-nowrap" *ngIf="baris.value.tipe == 'debit'">
                                        {{
                                            baris.value.debit | currency: "Rp. ":"symbol":"1.0-0"
                                        }}</span><br />
                                    <span class="text-danger font-weight-bold" *ngIf="baris.value.tipe == 'kredit'">CR</span>
                                    <span class="text-success font-weight-bold" *ngIf="baris.value.tipe == 'debit'">DB</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Detail Buku Besar -->
<div class="text-center" *ngIf="isBukuBesar" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
		padding-top: 1.5rem;
		padding-bottom: 0.8rem;
		margin-top: -2rem;
		background-color: #7cc774;
		">
        <h5 class="align-self-center text-white">
            <!-- <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large;"></fa-icon>
			{{ val.label }} -->
            Buku Besar
        </h5>
    </div>
    <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </small>
    <div *ngIf="!isLoading">
        <div *ngFor="let row of buku_besar.detail | keyvalue: asIsOrder | sortname; let i = index">
            <div class="container bg-footer" style="border-bottom: 0.3px solid #8b8b8b">
                <div class="form-group row bg-light py-2" style="border-bottom: 0.3px solid #8b8b8b">
                    <span class="
						col col-form-label
						text-left
						font-weight-bold
						align-self-center
						">
                        {{ row.akun }}
                    </span>
                </div>
                <div class="bg-footer">
                    <div class="form-group row">
                        <span class="col-4 col-form-label text-left font-weight-bold">Saldo Awal</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label text-right font-weight-bold">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading">
                                {{ row.saldo_awal | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                    <div class="form-group row">
                        <span class="col-4 col-form-label text-left">Mutasi Kredit</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label text-right">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading && row.total_kredit <= 0">-</div>
                            <div *ngIf="!isLoading && row.total_kredit > 0" class="
								{{ opsi == 'harta' || opsi == 'biaya' || opsi == 'prive' && row.akun_id != 30 && row.akun_id != 32 ? 'text-danger' : 'text-success' }}">
                                {{ row.total_kredit | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                    <div class="form-group row">
                        <span class="col-4 col-form-label py-n5 text-left">Mutasi Debit</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label py-n5 text-right">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading && row.total_debit <= 0">-</div>
                            <div *ngIf="!isLoading && row.total_debit > 0" class="
								{{ opsi == 'harta' || opsi == 'biaya' || opsi == 'prive' && row.akun_id != 30 && row.akun_id != 32 ? 'text-success' : 'text-danger' }}">
                                {{ row.total_debit | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                    <div class="form-group row">
                        <span class="col-4 col-form-label py-n5 text-left font-weight-bold">Saldo Akhir</span>
                        <div class="col-1 col-form-label">:</div>
                        <span class="col col-form-label py-n5 text-right font-weight-bold">
                            <small class="spinner-border spinner-border-sm text-info" role="status" *ngIf="isLoading">
                                <span class="sr-only">Loading...</span>
                            </small>
                            <div *ngIf="!isLoading">
                                {{ row.total_saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </div>
                        </span>
                    </div>
                </div>
                <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
                    <span class="sr-only">Loading...</span>
                </small>
                <div *ngIf="!isLoading">
                    <ul class="list-group {{ row.detail == undefined ? 'd-none' : '' }}" *ngFor="let baris of row.detail | keyvalue: asIsOrder">
                        <li class="list-group-item rounded-0 align-items-center mx-n3">
                            <div class="row">
                                <div class="text-left col-7">
                                    <small>
                                        <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: small" class="mr-1 text-biru"></fa-icon>
                                        {{ baris.value.tanggal | date: "dd MMM yyyy" }}
                                    </small>
                                    <br />
                                    <small [innerHTML]="baris.value.kode"></small><small *ngIf="baris.value.nama_cus!=null"> - {{baris.value.nama_cus}}</small>
                                    <br />
                                    <span [innerHTML]="baris.value.keterangan"></span>
                                    <br />
                                </div>
                                <div class="text-right col">
                                    <span class="{{ opsi == 'harta' || opsi == 'biaya' || opsi == 'prive' && row.akun_id != 30 && row.akun_id != 32 ? 'text-danger' : 'text-success' }} 
									font-weight-bold" *ngIf="baris.value.tipe == 'kredit'" style="white-space: nowrap;width: 100%;">
                                        {{ baris.value.kredit | currency: "Rp. ":"symbol":"1.0-0" }}
									</span>
                                    <span class="
									{{ opsi == 'harta' || opsi == 'biaya' || opsi == 'prive' && row.akun_id != 30 && row.akun_id != 32 ? 'text-success' : 'text-danger' }} 
									font-weight-bold" *ngIf="baris.value.tipe == 'debit'" style="white-space: nowrap;width: 100%;">
                                        {{ baris.value.debit | currency: "Rp. ":"symbol":"1.0-0" }}
									</span>
									<br />
                                    <span class="{{ opsi == 'harta' || opsi == 'biaya' || opsi == 'prive' && row.akun_id != 30 && row.akun_id != 32 ? 'text-danger' : 'text-success' }} 
									font-weight-bold" *ngIf="baris.value.tipe == 'kredit'">CR</span>
                                    <span class="{{ opsi == 'harta' || opsi == 'biaya' || opsi == 'prive' && row.akun_id != 30 && row.akun_id != 32 ? 'text-success' : 'text-danger' }} 
									font-weight-bold" *ngIf="baris.value.tipe == 'debit'">DB</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Detail Overdue -->
<div class="text-center" *ngIf="isOverdue || isBelumlunas" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
      padding-top: 1.5rem;
      padding-bottom: 0.8rem;
      margin-top: -2rem;
      background-color: #7cc774;
    ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </small>
    <div *ngIf="!isLoading">
        <div *ngIf="(overdue.jumlah_belumlunas <= 0 && isBelumlunas) || (overdue.pengingat.totalItems <= 0 && isOverdue)" 
			class="text-center" style="width: 100%; height: 50%">
            <img class="mt-5" style="width: 76%" src="assets/shape/empty.png" alt="empty state" />
            <h3 class="font-weight-bold mt-n3" style="color: #4a4a4a">
                Tidak Ada Data
            </h3>
            <small style="color: #969696">Silakan ganti bulan atau coba pilih<br />data yang lainnya</small>
        </div>
        <div *ngIf="isOverdue">
            <div *ngFor="let row of overdue.pengingat.list | keyvalue: asIsOrder">
                <div class="container">
                    <div class="form-group row bg-light py-2" style="border-bottom: 0.3px solid #8b8b8b">
                        <span class="
							col col-form-label
							text-left
							font-weight-bold
							align-self-center
						">
                            {{ row.value.customer }}
                        </span>
                        <span class="
							col col-form-label
							text-right
							font-weight-bold
							align-self-center
						">
                            {{ row.value.total | currency: "Rp. ":"symbol":"1.0-0"}}
                        </span>
                    </div>
                    <div *ngIf="!isLoading" class="mt-n3">
                        <ul class="list-group" *ngFor="let baris of row.value.detail | keyvalue: asIsOrder">
                            <li class="list-group-item rounded-0 align-items-center mx-n3">
                                <div class="row">
                                    <div class="col-7 text-left">
                                        <small>
                                            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: small" class="mr-1 text-biru"></fa-icon>
                                            {{ baris.value.jatuh_tempo | date: "dd MMM yyyy" }}
                                        </small>
                                        <br />
                                        <small [innerHTML]="baris.value.kode"></small>
                                        <br />
                                        <span [innerHTML]="baris.value.deskripsi"></span>
                                        <br />
                                    </div>
                                    <div class="col-5 text-right">
                                        <span class="font-weight-bold text-nowrap">
                                            {{
                        baris.value.total | currency: "Rp. ":"symbol":"1.0-0"
                      }}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isBelumlunas">
			<!-- {{overdue.belum_lunas.list | json}} -->
            <div *ngFor="let row of overdue.belum_lunas.list | keyvalue: asIsOrder">
                <div class="container">
                    <div class="form-group row bg-footer py-2" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="row.value.sisa_bayar>0">
                        <span class="
								col col-form-label
								text-left
								font-weight-bold
								align-self-center
							">
                            {{ row.value.nama_cus }}
                        </span>
                        <span class="
								col col-form-label
								text-right
								font-weight-bold
								align-self-center
							">
                            {{ row.value.sisa_bayar | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                    <div *ngIf="!isLoading" class="mt-n3">
                        <ul class="list-group" *ngFor="let baris of row.value.detail | keyvalue: asIsOrder">
                            <li class="list-group-item rounded-0 align-items-center mx-n3">
                                <div class="row">
                                    <div class="col-7 text-left">
                                        <small>
                                            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: small" class="mr-1 text-biru"></fa-icon>
                                            {{ baris.value.tanggal_invoice | date: "dd MMM yyyy" }}
                                        </small>
                                        <br />
                                        <small [innerHTML]="baris.value.kode"></small>
                                        <br />
                                        <span [innerHTML]="baris.value.catatan"></span>
                                        <br />
                                    </div>
                                    <div class="col-5 text-right">
                                        <span class="font-weight-bold text-nowrap">
                                            {{ baris.value.sisa_bayar | currency: "Rp. ":"symbol":"1.0-0" }}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Detail Reminder Invoices -->
<div class="text-center" *ngIf="isReminderInv" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
      padding-top: 1.5rem;
      padding-bottom: 0.8rem;
      margin-top: -2rem;
      background-color: #7cc774;
    ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </small>
    <div *ngIf="!isLoading">
        <div>
            <table class="table-responsive-sm" style="width: 100%;">
                <tr>
                    <td class="text-center" (click)="seeReminderInv('overdue')"
                        style="cursor: pointer;background-color: {{reminder_inv_opsi == 'overdue' ? '#f5f9fd' : '#ffffff'}}; width: 50%;border-right: 0.5px solid #c4c4c4;">
                        Overdue
                        <br>
                        <span class="text-danger">
                            <b>
                                {{ totalInvOverdue | currency: "Rp. ":"symbol":"1.0-0" }}
                            </b>
                            <span *ngIf="jumlahInvOverdue > 0">
                                &nbsp;({{jumlahInvOverdue}})
                            </span>
                        </span>
                    </td>
                    <td class="text-center" (click)="seeReminderInv('scheduled')"
                        style="cursor: pointer;background-color: {{reminder_inv_opsi == 'scheduled' ? '#f5f9fd' : '#ffffff'}}; width: 50%;border-right: 0.5px solid #c4c4c4;">
                        Scheduled
                        <br>
                        <span class="text-success">
                            <b>
                                {{ totalInvScheduled | currency: "Rp. ":"symbol":"1.0-0" }}
                            </b>
                            <span *ngIf="jumlahInvScheduled > 0">
                                &nbsp;({{jumlahInvScheduled}})
                            </span>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="(reminder_inv_opsi == 'scheduled' && totalInvScheduled <= 0) || (reminder_inv_opsi == 'overdue' && totalInvOverdue <= 0)" >
                    <td colspan="2">
                        <div class="text-center" style="width: 100%; height: 50%">
                            <img class="mt-5" src="assets/shape/empty.png" alt="empty state" />
                            <h3 class="font-weight-bold mt-n3" style="color: #4a4a4a">
                                Tidak Ada Data
                            </h3>
                        </div>
                    </td>
                </tr>
                <ng-container *ngFor="let row of data_repeat_reminder_inv">
                    <tr>
                        <td style="border-top: 1px solid #c4c4c4; padding: 5px;">
                            <b>
                                ({{row.periode_text}}) - {{row.tgl_invoice_formated}}
                                <svg *ngIf="rowReminderInvId != row.id" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" (click)="printReminderInv(row)" style="cursor: pointer; margin-bottom: 4px; margin-left: 5px;">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                                </svg>
                                <svg *ngIf="rowReminderInvId == row.id && isShowReminderInv == true" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" (click)="hideReminderInv(row)" style="cursor: pointer; margin-bottom: 4px; margin-left: 5px;fill:#009aac;">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                                </svg>
                                <a href="{{row.link_spk}}" target="_blank" *ngIf="row.is_url_spk == 1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style="cursor: pointer; margin-bottom: 4px; margin-left: 10px;fill:#000000;">
                                        <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"/>
                                    </svg>
                                </a>
                            </b>
                            <br>
                            {{row.kode_spk}} - {{row.nama_customer}}
                        </td>
                        <td class="text-right" style="border-top: 1px solid #c4c4c4; padding: 5px;">
                            <b>
                                {{ row.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </b>
                        </td>
                    </tr>
                    <tr *ngIf="rowReminderInvId == row.id && isShowReminderInv == true">
                        <td colspan="2">
                            <iframe height="900" width="100%" [src]="urlReminderInv"></iframe>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</div>

<!-- Detail Umur Piutang -->
<div class="text-center" *ngIf="(isOverdue || isUmurPiutang) && !isBelumlunas" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
      padding-top: 1.5rem;
      padding-bottom: 0.8rem;
      margin-top: -2rem;
      background-color: #7cc774;
    ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </small>
    <div *ngIf="!isLoading">
        <div *ngIf="(overdue.jumlah_belumlunas <= 0 && isBelumlunas) || (overdue.pengingat.totalItems <= 0 && isOverdue)" 
			class="text-center" style="width: 100%; height: 50%">
            <img class="mt-5" style="width: 76%" src="assets/shape/empty.png" alt="empty state" />
            <h3 class="font-weight-bold mt-n3" style="color: #4a4a4a">
                Tidak Ada Data
            </h3>
        </div>
        <div>
            <table class="table-responsive-sm" style="width: 100%;">
                <tr class="text-center" style="height: 65px;">
                    <td class="text-center" colspan="3" (click)="seeBelumlunas('umur_piutang')"
                        style="cursor:pointer;">
                        <h4 class="font-weight-bold m-0">
                            <b>{{ overdue.total_belumlunas | currency: "Rp. ":"symbol":"1.0-0" }}</b>
                        </h4>
                        {{ overdue.jumlah_belumlunas }} Belum Bayar
                    </td>
                </tr>
                <tr>
                    <td class="text-center" (click)="seeUmurPiutang('kurang30')"
                        style="zoom: 85%; cursor: pointer;background-color: {{umur_piutang_opsi == 'kurang30' ? '#f5f9fd' : '#ffffff'}}; width: 33.3%;border-right: 0.5px solid #c4c4c4;">
                        < 30 Hari
                        <br>
                        <span class="text-success">
                            <b>
                                {{ overdue.umur_piutang.kurang30.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </b>
                            <span *ngIf="overdue.umur_piutang.jumlahKurang30 > 0">
                                &nbsp;({{overdue.umur_piutang.jumlahKurang30}})
                            </span>
                        </span>
                    </td>
                    <td class="text-center" (click)="seeUmurPiutang('kurang60')"
                        style="zoom: 85%; cursor: pointer;background-color: {{umur_piutang_opsi == 'kurang60' ? '#f5f9fd' : '#ffffff'}}; width: 33.3%;border-right: 0.5px solid #c4c4c4;">
                        31-60 Hari
                        <br>
                        <span class="text-warning">
                            <b>
                                {{ overdue.umur_piutang.kurang60.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </b>
                            <span *ngIf="overdue.umur_piutang.jumlahKurang60 > 0">
                                &nbsp;({{overdue.umur_piutang.jumlahKurang60}})
                            </span>
                        </span>
                    </td>
                    <td class="text-center" (click)="seeUmurPiutang('lebih60')"
                        style="zoom: 85%; cursor: pointer;background-color: {{umur_piutang_opsi == 'lebih60' ? '#f5f9fd' : '#ffffff'}}; width: 33.3%;">
                        > 60 Hari
                        <br>
                        <span class="text-orange">
                            <b>
                                {{ overdue.umur_piutang.lebih60.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </b>
                            <span *ngIf="overdue.umur_piutang.jumlahLebih60 > 0">
                                &nbsp;({{overdue.umur_piutang.jumlahLebih60}})
                            </span>
                        </span>
                    </td>
                </tr>
                <ng-container *ngFor="let row of data_repeat_umur_piutang">
                    <tr>
                        <td colspan="2" style="border-top: 1px solid #c4c4c4; padding: 5px;">
                            <b>
                                ({{row.periode_text}}) - {{row.tanggal_invoice}}
                                <!-- <a href="https://accsystems.landa.co.id/api/t_bayar_piutang/print/bukti/{{row.pos_bayar_piutang_id}}?config={{val.key}}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" (click)="printInvoices(row)" style="cursor: pointer; margin-bottom: 4px; margin-left: 5px;">
                                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                                    </svg>
                                </a> -->
                                <svg *ngIf="rowInvoiceId != row.pos_bayar_piutang_id" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" (click)="printInvoices(row)" style="cursor: pointer; margin-bottom: 4px; margin-left: 5px;">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                                </svg>
                                <svg *ngIf="rowInvoiceId == row.pos_bayar_piutang_id && isShowInvoice == true" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" (click)="hideInvoices(row)" style="cursor: pointer; margin-bottom: 4px; margin-left: 5px;fill:#009aac;">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                                </svg>
                                <a href="{{row.link_spk}}" target="_blank" *ngIf="row.is_url_spk == 1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style="cursor: pointer; margin-bottom: 4px; margin-left: 10px;fill:#000000;">
                                        <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"/>
                                    </svg>
                                </a>
                            </b>
                            <br>
                            {{row.kode}} - {{row.nama_customer}}
                        </td>
                        <td class="text-right" style="border-top: 1px solid #c4c4c4; padding: 5px;">
                            <b>
                                {{ row.sisa_bayar | currency: "Rp. ":"symbol":"1.0-0" }}
                            </b>
                        </td>
                    </tr>
                    <tr *ngIf="rowInvoiceId == row.pos_bayar_piutang_id && isShowInvoice == true">
                        <td colspan="3">
                            <iframe height="900" width="100%" [src]="urlInvoice"></iframe>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</div>

<!-- Detail Profit -->
<div *ngIf="isProfit" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center text-center" style="
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      margin-top: -2rem;
      background-color: #7cc774;
    ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <div class="navbar navbar-expand-lg navbar-dark bg-footer container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div>
    <div class="mb-3 mt-n1">
        <div class="text-center">
            <small class="spinner-border spinner-border-sm text-info mt-5" role="status" *ngIf="isLoading">
                <span class="sr-only">Loading...</span>
            </small>
        </div>
        <div *ngIf="!isLoading" class="">
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-light" *ngIf="objLength(profit.detail.PENDAPATAN.detail) > 0">
                    <div class="
						d-flex
						justify-content-between
						text-uppercase
						font-weight-bold
						">
                        <span>Pendapatan Usaha</span>
                    </div>
                </li>
            </ul>
            <ul class="list-group" *ngFor="let val of profit.detail.PENDAPATAN.detail | keyvalue: asIsOrder">
                <li class="list-group-item rounded-0" (click)="bukubesar(val.value.id, 'profit', 'pendapatan')" role="button">
                    <div class="d-flex justify-content-between {{ bold(val.value.nama) }}">
                        <span> {{ val.value.kode }} - {{ val.value.nama2 }} </span>
                        <span class="text-nowrap">
                            {{ val.value.nominal | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-footer">
                    <div class="d-flex justify-content-between font-weight-bold text-biru">
                        <span class="pr-2 text-uppercase">Total Pendapatan Usaha</span>
                        <span class="text-nowrap">
                            {{ profit.detail.PENDAPATAN.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-light" *ngIf="objLength(profit.detail.BEBAN.detail) > 0">
                    <div class="
						d-flex
						justify-content-between
						text-uppercase
						font-weight-bold
						">
                        <span>Beban Usaha</span>
                    </div>
                </li>
            </ul>
            <ul class="list-group" *ngFor="let val of profit.detail.BEBAN.detail | keyvalue: asIsOrder">
                <li class="list-group-item rounded-0" (click)="bukubesar(val.value.id, 'profit', 'biaya')" role="button">
                    <div class="d-flex justify-content-between {{ bold(val.value.nama) }}">
                        <span> {{ val.value.kode }} - {{ val.value.nama2 }}</span>
                        <span class="text-nowrap">
                            {{ val.value.nominal | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-footer">
                    <div class="d-flex justify-content-between font-weight-bold text-biru">
                        <span class="pr-2 text-uppercase">Total Beban Usaha</span>
                        <span class="text-nowrap">
                            {{ profit.detail.BEBAN.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
                <li class="list-group-item rounded-0 
				{{ profit.data.total_operasional >= 0 ? 'bg-success' : 'bg-danger' }} text-white">
                    <div class="d-flex justify-content-between font-weight-bold">
                        <span class="pr-2 text-uppercase">
							{{ profit.data.total_operasional >= 0 ? "Laba" : "Rugi" }} Operasional
						</span>
                        <span class="text-nowrap">
                            {{ profit.data.total_operasional | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-light" *ngIf="objLength(profit.detail.PENDAPATAN_DILUAR_USAHA.detail) > 0">
                    <div class="
						d-flex
						justify-content-between
						text-uppercase
						font-weight-bold
						">
                        <span>Pendapatan Diluar Usaha</span>
                    </div>
                </li>
            </ul>
            <ul class="list-group" *ngFor="let val of profit.detail.PENDAPATAN_DILUAR_USAHA.detail | keyvalue: asIsOrder">
                <li class="list-group-item rounded-0" (click)="bukubesar(val.value.id, 'profit', 'pendapatan')" role="button">
                    <div class="d-flex justify-content-between {{ bold(val.value.nama) }}">
                        <span> {{ val.value.kode }} - {{ val.value.nama2 }} </span>
                        <span class="text-nowrap">
                            {{ val.value.nominal | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-footer">
                    <div class="d-flex justify-content-between font-weight-bold text-biru">
                        <span class="pr-2 text-uppercase">Total Pendapatan Diluar Usaha</span>
                        <span class="text-nowrap">
                            {{ profit.detail.PENDAPATAN_DILUAR_USAHA.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-light" *ngIf="objLength(profit.detail.BEBAN_DILUAR_USAHA.detail) > 0">
                    <div class="
						d-flex
						justify-content-between
						text-uppercase
						font-weight-bold
						">
                        <span>Beban Diluar Usaha</span>
                    </div>
                </li>
            </ul>
            <ul class="list-group" *ngFor="let val of profit.detail.BEBAN_DILUAR_USAHA.detail | keyvalue: asIsOrder">
                <li class="list-group-item rounded-0" (click)="bukubesar(val.value.id, 'profit', 'biaya')" role="button">
                    <div class="d-flex justify-content-between {{ bold(val.value.nama) }}">
                        <span> {{ val.value.kode }} - {{ val.value.nama2 }} </span>
                        <span class="text-nowrap">
                            {{ val.value.nominal | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="list-group">
                <li class="list-group-item rounded-0 bg-footer">
                    <div class="d-flex justify-content-between font-weight-bold text-biru">
                        <span class="pr-2 text-uppercase">Total Beban Diluar Usaha</span>
                        <span class="text-nowrap">
                            {{ profit.detail.BEBAN_DILUAR_USAHA.total | currency: "Rp. ":"symbol":"1.0-0" }}
						</span>
                    </div>
                </li>
                <li class="list-group-item rounded-0 
				 {{ profit.data.total >= 0 ? 'bg-success' : 'bg-danger' }} text-white">
                    <div class="d-flex justify-content-between font-weight-bold">
                        <span class="pr-2 text-uppercase">
							{{ profit.data.total >= 0 ? "Laba" : "Rugi" }}
						</span>
                        <span class="text-nowrap">
                            {{ profit.data.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Detail Cashflow -->
<div class="text-center" *ngIf="isCashflow" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
      padding-top: 1.5rem;
      padding-bottom: 0.8rem;
      margin-top: -2rem;
      background-color: #7cc774;
    ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <div class="navbar navbar-expand-lg navbar-dark bg-footer container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div>
    <small class="spinner-border spinner-border-sm text-info mt-5" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </small>
    <div class="accordion text-left" id="accordionExample" *ngIf="!isLoading">
        <div class="card">
            <div class="card-header text-uppercase" id="headingOne" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span class="font-weight-bold">Saldo Kas Awal</span>
            </div>
            <div id="collapseOne" class="show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <ul class="list-group" *ngFor="let val of cashflow.data.saldo_awal.detail | keyvalue: asIsOrder">
                    <li class="list-group-item rounded-0 {{ val.value.total > 0 || val.value.total < 0 ? '' : 'd-none' }}" 
						(click)="detailjurnal(val.value, 'cashflow')" role="button">
                        <div class="d-flex justify-content-between">
                            <span>
                                {{ val.value.kode }}
                            </span>
                            <span class="text-nowrap">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>
                                {{ val.value.nama }}
                            </span>
                            <span class="text-nowrap text-white">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
                <ul class="list-group">
                    <li class="list-group-item rounded-0 bg-footer">
                        <div class="
                                d-flex
                                justify-content-between
                                font-weight-bold
                                mb-0
                                text-biru
                            ">
                            <span class="pr-2">Total Saldo Kas Awal</span>
                            <span class="text-nowrap text-success">
                                {{
                                    cashflow.data.saldo_awal.total | currency: "Rp. ":"symbol":"1.0-0"
                                }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card">
            <div *ngFor="let val of cashflow.detail | keyvalue: asIsOrder">
                <div class="card-header">
                    <span class="mb-0 font-weight-bold text-uppercase">
                        {{ val.key }}
                    </span>
                </div>
                <ul class="list-group">
                    <li class="list-group-item rounded-0">
                        <div class="mx-n4 mt-n3 mb-n2">
                            <ul class="list-group" *ngFor="let row of cashflow.detail[val.key].detail | keyvalue: asIsOrder">
                                <li class="list-group-item rounded-0 bg-footer capitalize font-weight-bold
                  					{{ row.key == 'nama' || row.key == 'total' ? 'd-none' : '' }}">
                                    {{ row.key }}
                                </li>
                                <li class="list-group-item rounded-0">
                                    <div class="mx-n4 mt-n3 mb-n2">
                                        <ul class="list-group" *ngFor="let baris of cashflow.detail[val.key].detail[row.key].detail | keyvalue: asIsOrder">
                                            <li class="list-group-item rounded-0 {{ baris.key == 'nama' || baris.key == 'total' ? 'd-none' : '' }}" 
											>
                                                <div class="d-flex justify-content-between">
                                                    <span class="pr-2" (click)="detailjurnal(baris.value.akun, 'cashflow', row.key)" role="button">
                                                        {{ baris.value.akun.kode }}
                                                    </span>
                                                    <span class="text-nowrap" *ngIf="baris.value.total < 0" (click)="detailjurnal(baris.value.akun, 'cashflow', row.key)" role="button">
                                                        {{ baris.value.total * -1 | currency: "Rp. ":"symbol":"1.0-0" }}
                                                    </span>
                                                    <span class="text-nowrap" *ngIf="baris.value.total >= 0" (click)="detailjurnal(baris.value.akun, 'cashflow', row.key)" role="button">
                                                        {{ baris.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                                    </span>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <span class="pr-2">
                                                        <span (click)="detailjurnal(baris.value.akun, 'cashflow', row.key)" role="button">
                                                            {{ baris.value.akun.nama }}
                                                        </span>
                                                        <span *ngIf="val.key == 'Tidak Terklasifikasi'" (click)="openVerticallyCentered(modalArusKas); setAkunIdArusKas(baris.value.akun.id)" style="cursor:pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="13px" viewBox="0 0 576 512" style="margin-bottom: 5px;">
                                                                <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span class="text-nowrap text-white" *ngIf="baris.value.total < 0" (click)="detailjurnal(baris.value.akun, 'cashflow', row.key)" role="button">
                                                        {{ baris.value.total * -1 | currency: "Rp. ":"symbol":"1.0-0" }}
                                                    </span>
                                                    <span class="text-nowrap text-white" *ngIf="baris.value.total >= 0" (click)="detailjurnal(baris.value.akun, 'cashflow', row.key)" role="button">
                                                        {{ baris.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="list-group" style="margin-bottom: -0.8rem">
                                            <li class="list-group-item rounded-0">
                                                <div class="
														d-flex
														justify-content-between
														font-weight-bold
													">
                                                    <span class="pr-2">Total</span>
                                                    <span class="text-nowrap" *ngIf="row.value.total < 0">
                                                        {{ row.value.total * -1 | currency: "Rp. ":"symbol":"1.0-0" }}
                                                    </span>
                                                    <span class="text-nowrap" *ngIf="row.value.total >= 0">
                                                        {{ row.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="list-group-item rounded-0 bg-footer" style="margin-top: -0.3rem">
                        <div class="
								mx-n1
								d-flex
								justify-content-between
								font-weight-bold
								text-biru
							">
                            <span class="pr-2">
                                {{ val.value.total >= 0 ? "Kenaikan" : "Penurunan" }} Kas dari {{ val.key }}
                            </span>
                            <span class="text-nowrap {{ val.value.total >= 0 ? 'text-success' : 'text-danger' }}" 
								*ngIf="val.value.total < 0">
                                {{ val.value.total * -1 | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                            <span class="text-nowrap {{ val.value.total >= 0 ? 'text-success' : 'text-danger' }}" 
								*ngIf="val.value.total >= 0">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-header font-weight-bold {{ cashflow.data.saldo_biaya >= 0 ? 'bg-success' : 'bg-danger' }} text-white">
                <div class="d-flex justify-content-between">
                    <span class="pr-2">
                        {{ cashflow.data.saldo_biaya >= 0 ? "Kenaikan" : "Penurunan" }} Kas
                    </span>
                    <span class="text-nowrap">
                        {{ cashflow.data.saldo_biaya | currency: "Rp. ":"symbol":"1.0-0" }}
                    </span>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header font-weight-bold text-uppercase" id="headingFour" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span>Saldo Kas Akhir</span>
            </div>
            <div id="collapseFour" aria-labelledby="headingFour" data-parent="#accordionExample">
                <ul class="list-group" *ngFor="let val of cashflow.data.saldo_akhir.detail | keyvalue: asIsOrder">
                    <li class="list-group-item rounded-0 {{ val.value.total > 0 || val.value.total < 0 ? '' : 'd-none' }}" 
						(click)="detailjurnal(val.value, 'cashflow')" role="button">
                        <div class="d-flex justify-content-between">
                            <span>
                                {{ val.value.kode }}
                            </span>
                            <span class="text-nowrap">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>
                                {{ val.value.nama }}
                            </span>
                            <span class="text-nowrap text-white">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
                <ul class="list-group">
                    <li class="list-group-item rounded-0 bg-footer">
                        <div class="d-flex justify-content-between font-weight-bold text-biru">
                            <span class="pr-2">Total Saldo Kas Akhir</span>
                            <span class="text-nowrap {{ cashflow.data.saldo_akhir.total >= 0 ? 'text-success' : 'text-danger' }}">
                                {{ cashflow.data.saldo_akhir.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Detail Jurnal -->
<div class="text-center" *ngIf="isJurnalpenerimaan || isJurnalpengeluaran" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      margin-top: -2rem;
      background-color: #7cc774;
    ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <div class="navbar navbar-expand-lg navbar-dark bg-footer container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div>
    <div class="pt-3 pb-3 mt-n4 bg-footer" (click)="seeCashflow()" role="button">
        <span>{{ cashflow.data.saldo_biaya >= 0 ? "Kenaikan" : "Penurunan" }} Kas</span>
        <h5 class="text-center mb-n2">
            <div class="font-weight-bold {{
          cashflow.data.saldo_biaya >= 0 ? 'text-success' : 'text-orange'
        }}">
                {{ cashflow.data.saldo_biaya | currency: "Rp. ":"symbol":"1.0-0" }}
            </div>
        </h5>
        <small class="text-secondary" style="font-size: smaller">
            Lihat Detail
        </small>
    </div>
    <div class="d-flex justify-content-around">
        <div (click)="seeJurnalpenerimaan()" class="{{
        isJurnalpenerimaan == true ? 'bg-white' : 'head-item'
      }} w-100 py-3" role="button">
            <span>
                <div class="mb-n4 text-success">
                    {{
            jurnal_penerimaan.data.total.debit
              | currency: "Rp. ":"symbol":"1.0-0"
          }}
                </div>
            </span><br />
            <span>Jurnal Penerimaan</span>
        </div>
        <div (click)="seeJurnalpengeluaran()" class="{{
        isJurnalpengeluaran == true ? 'bg-white' : 'head-item'
      }} w-100 py-3" role="button">
            <span>
                <div class="mb-n4 text-danger">
                    {{
            jurnal_pengeluaran.data.total.kredit
              | currency: "Rp. ":"symbol":"1.0-0"
          }}
                </div>
            </span><br />
            <span>Jurnal Pengeluaran</span>
        </div>
    </div>
    <div class="mb-3" *ngIf="isJurnalpenerimaan && !isJurnalpengeluaran">
        <small class="spinner-border spinner-border-sm text-info mt-5" role="status" *ngIf="isLoading">
            <span class="sr-only">Loading...</span>
        </small>
        <div *ngIf="!isLoading" class="mt-3">
            <div class="text-left font-weight-bold py-3 mt-n3 mb-3 container bg-light">
                Debit
            </div>
            <div *ngFor="let val of jurnal_penerimaan.data.total_akun.debit">
                <div class="w-100 container {{
            val.total > 0 || val.total < 0 ? '' : 'd-none'
          }}" (click)="detailjurnal(val.akun, 'jurnal_penerimaan', 'debit')" role="button">
                    <div class="d-flex justify-content-between">
                        <span class="pr-2 text-left">
                            <small>
                                {{ val.akun.kode }}
                            </small>
                            <br />
                            {{ val.akun.nama }}
                        </span>
                        <span class="text-right text-nowrap font-weight-bold text-right">
                            {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </div>
                <hr />
            </div>
            <div class="text-left font-weight-bold py-3 mt-n3 mb-3 container bg-light">
                Kredit
            </div>
            <div *ngFor="let val of jurnal_penerimaan.data.total_akun.kredit">
                <div class="w-100 container {{
            val.total > 0 || val.total < 0 ? '' : 'd-none'
          }}" (click)="detailjurnal(val.akun, 'jurnal_penerimaan', 'kredit')" role="button">
                    <div class="d-flex justify-content-between">
                        <span class="pr-2 text-left">
                            <small>
                                {{ val.akun.kode }}
                            </small>
                            <br />
                            {{ val.akun.nama }}
                        </span>
                        <span class="text-right text-nowrap font-weight-bold text-right">
                            {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
    <div class="mb-3" *ngIf="!isJurnalpenerimaan && isJurnalpengeluaran">
        <div class="spinner-border spinner-border-sm text-info mt-5" role="status" *ngIf="isLoading">
            <span class="sr-only">Loading...</span>
        </div>
        <div *ngIf="!isLoading" class="mt-3">
            <div class="text-left font-weight-bold py-3 mt-n3 mb-3 container bg-light">
                Debit
            </div>
            <div *ngFor="let val of jurnal_pengeluaran.data.total_akun.debit">
                <div class="w-100 container {{
            val.total > 0 || val.total < 0 ? '' : 'd-none'
          }}" (click)="detailjurnal(val.akun, 'jurnal_pengeluaran', 'debit')" role="button">
                    <div class="d-flex justify-content-between">
                        <span class="pr-2 text-left">
                            <small>
                                {{ val.akun.kode }}
                            </small>
                            <br />
                            {{ val.akun.nama }}
                        </span>
                        <span class="text-right text-nowrap font-weight-bold text-right">
                            {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </div>
                <hr />
            </div>
            <div class="text-left font-weight-bold py-3 mt-n3 mb-3 container bg-light">
                Kredit
            </div>
            <div *ngFor="let val of jurnal_pengeluaran.data.total_akun.kredit">
                <div class="w-100 container {{
            val.total > 0 || val.total < 0 ? '' : 'd-none'
          }}" (click)="detailjurnal(val.akun, 'jurnal_pengeluaran', 'kredit')" role="button">
                    <div class="d-flex justify-content-between">
                        <span class="pr-2 text-left">
                            <small>
                                {{ val.akun.kode }}
                            </small>
                            <br />
                            {{ val.akun.nama }}
                        </span>
                        <span class="text-right text-nowrap font-weight-bold text-right">
                            {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
</div>

<!-- Subdetail Cashflow -->
<div class="text-center" *ngIf="detailCashflow || detailJurnalpenerimaan || detailJurnalpengeluaran" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
        padding-top: 1.5rem;
        padding-bottom: 0.8rem;
        margin-top: -2rem;
        background-color: #7cc774;
        ">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <div class="navbar navbar-expand-lg navbar-dark bg-footer container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div>
    <small class="spinner-border spinner-border-sm text-info my-5" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </small>
    <div *ngIf="!isLoading">
        <div class="container form-group bg-light py-3" style="border-bottom: 0.3px solid #8b8b8b">
            <span class="d-flex text-left font-weight-bold align-self-center">
                {{ temp.kode }} - {{ temp.nama }}
            </span>
        </div>
        <div class="mt-n3">
            <div *ngIf="posisi == 'jurnal_penerimaan' && opsi == 'debit'">
                <div *ngFor="let val of jurnal_penerimaan.debit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-success
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                DB
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let val of jurnal_penerimaan.data.total_akun.debit">
                    <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between font-weight-bold">
                            <span>Total</span>
                            <span class="text-nowrap text-success">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="posisi == 'jurnal_penerimaan' && opsi == 'kredit'">
                <div *ngFor="let val of jurnal_penerimaan.kredit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-danger
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                CR
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let val of jurnal_penerimaan.data.total_akun.kredit">
                    <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between font-weight-bold">
                            <span>Total</span>
                            <span class="text-nowrap text-danger">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="posisi == 'jurnal_pengeluaran' && opsi == 'debit'">
                <div *ngFor="let val of jurnal_pengeluaran.debit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-success
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                DB
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let val of jurnal_pengeluaran.data.total_akun.debit">
                    <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between font-weight-bold">
                            <span>Total</span>
                            <span class="text-nowrap text-success">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="posisi == 'jurnal_pengeluaran' && opsi == 'kredit'">
                <div *ngFor="let val of jurnal_pengeluaran.kredit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-danger
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                CR
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let val of jurnal_pengeluaran.data.total_akun.kredit">
                    <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between font-weight-bold">
                            <span>Total</span>
                            <span class="text-nowrap text-danger">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="posisi == 'cashflow' && (opsi != 'kredit' || opsi != 'debit')">
                <!-- <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b;"
            *ngIf="jurnal_penerimaan.debit.length>=1 || jurnal_pengeluaran.debit.length>=1">
            <div class="d-flex justify-content-between font-weight-bold">
                <span>Debit</span>
                <br>
                {{jurnal_penerimaan.debit.length}} - {{jurnal_pengeluaran.debit.length}}
            </div>
        </div> -->
                <div *ngFor="let val of jurnal_penerimaan.debit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-success
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                DB
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let val of jurnal_pengeluaran.debit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-success
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                DB
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div *ngFor="let val of jurnal_penerimaan.data.total_akun.debit">
          <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b;"
               *ngIf="val.akun.id == temp.id">
            <div class="d-flex justify-content-between font-weight-bold text-biru">
              <span>Total</span>
              <span class="text-nowrap {{ val.total<0 ? 'text-danger' : '' }}">
                {{ val.total | currency:"Rp. ":'symbol' : '1.0-0' }}
              </span>
            </div>
          </div>
        </div>
        <div *ngFor="let val of jurnal_pengeluaran.data.total_akun.debit">
          <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b;"
               *ngIf="val.akun.id == temp.id">
            <div class="d-flex justify-content-between font-weight-bold text-biru">
              <span>Total</span>
              <span class="text-nowrap {{ val.total<0 ? 'text-danger' : '' }}">
                  {{ val.total | currency:"Rp. ":'symbol' : '1.0-0' }}
              </span>
            </div>
          </div>
        </div> -->
                <!-- <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b;"
        *ngIf="jurnal_penerimaan.kredit.length>=1 || jurnal_pengeluaran.kredit.length>=1">
            <div class="d-flex justify-content-between font-weight-bold">
                <span>Kredit</span>
                <br>
                {{ jurnal_penerimaan.kredit.length }} - {{ jurnal_penerimaan.kredit.length }}
            </div>
        </div> -->
                <div *ngFor="let val of jurnal_penerimaan.kredit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-danger
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                CR
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let val of jurnal_pengeluaran.kredit">
                    <div class="container py-3" style="border-bottom: 0.3px solid #8b8b8b" *ngIf="val.akun.id == temp.id">
                        <div class="d-flex justify-content-between">
                            <span class="pr-2 text-left">
                                <small>
                                    {{ val.kode }}<span *ngIf="val.nama_cus!=null"> - {{val.nama_cus}}</span>
                                </small>
                                <br />
                                <span [innerHtml]="val.keterangan"></span>
                            </span>
                            <span class="
                                text-right text-nowrap
                                font-weight-bold
                                text-right text-danger
                                ">
                                {{ val.total | currency: "Rp. ":"symbol":"1.0-0" }}
                                <br />
                                CR
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div *ngFor="let val of jurnal_penerimaan.data.total_akun.kredit">
          <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b;"
               *ngIf="val.akun.id == temp.id">
            <div class="d-flex justify-content-between font-weight-bold text-biru">
              <span>Total</span>
              <span class="text-nowrap {{ val.total<0 ? 'text-success' : 'text-danger' }}">
                {{ val.total | currency:"Rp. ":'symbol' : '1.0-0' }}
              </span>
            </div>
          </div>
        </div>
        <div *ngFor="let val of jurnal_pengeluaran.data.total_akun.kredit">
          <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b;"
               *ngIf="val.akun.id == temp.id">
            <div class="d-flex justify-content-between font-weight-bold text-biru">
              <span>Total</span>
              <span class="text-nowrap {{ val.total<0 ? 'text-success' : 'text-danger' }}">
                {{ val.total | currency:"Rp. ":'symbol' : '1.0-0' }}
              </span>
            </div>
          </div>
        </div> -->
                <div class="container py-3 bg-footer" style="border-bottom: 0.3px solid #8b8b8b">
                    <div class="d-flex justify-content-between font-weight-bold text-biru">
                        <span>Total</span>
                        <span class="text-nowrap 
                            {{ total_jurnal >= 0 ? 'text-success' : 'text-danger' }}">
                            {{ total_jurnal | currency: "Rp. ":"symbol":"1.0-0" }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Detail Neraca -->
<div *ngIf="isNeraca" style="margin-bottom: 5rem">
    <div class="d-flex justify-content-center" style="
		padding-top: 1.5rem;
		padding-bottom: 0.8rem;
		margin-top: -2rem;
		background-color: #7cc774;
		">
        <h5 class="align-self-center text-white">
            <fa-icon [icon]="['fas', 'building']" class="mr-1" style="font-size: large"></fa-icon>
            {{ val.label }}
        </h5>
    </div>
    <div class="navbar navbar-expand-lg navbar-dark bg-footer container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true || isLoading ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div>
    <div class="text-center">
        <small class="spinner-border spinner-border-sm text-center text-info mt-5" role="status" *ngIf="isLoading">
            <span class="sr-only">Loading...</span>
        </small>
    </div>
    <div class="accordion text-left" id="accordionExample" *ngIf="!isLoading">
        <div class="card">
            <div class="card-header font-weight-bold text-uppercase" id="headingOne" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span>Harta</span>
            </div>
            <div id="collapseOne" class="show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <ul class="list-group" *ngFor="let val of neraca.modelHarta.list | keyvalue: asIsOrder">
                    <li class="list-group-item rounded-0 bg-footer font-weight-bold" (click)="bukubesar(val.value.id, 'neraca', 'harta')" role="button">
                        {{ val.value.nama }}
                    </li>
                    <li class="list-group-item rounded-0 rounded-0" 
						*ngFor="let row of neraca.modelHarta.list[val.key].detail | keyvalue: asIsOrder" 
						(click)="bukubesar(row.value.id, 'neraca', 'harta')" role="button">
                        <div class="d-flex justify-content-between">
                            <span>{{ row.value.kode }}</span>
                            <span class="text-nowrap">
                                {{ row.value.saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ row.value.nama }}</span>
                            <span class="text-nowrap text-white">
                                {{ row.value.saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                    <li class="list-group-item rounded-0">
                        <div class="d-flex justify-content-between font-weight-bold">
                            <span> Total {{ val.value.nama }} </span>
                            <span class="text-nowrap">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
                <ul class="list-group">
                    <li class="list-group-item rounded-0 bg-biru">
                        <div class="
								d-flex
								justify-content-between
								font-weight-bold
								mb-0
								text-white
							">
                            <span>Total Harta</span>
                            <span class="text-nowrap {{ neraca.modelHarta.total < 0 ? 'text-danger' : '' }}">
                                {{ neraca.modelHarta.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-header font-weight-bold text-uppercase" id="headingTwo" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <span>Kewajiban</span>
            </div>
            <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <ul class="list-group" *ngFor="let val of neraca.modelKewajiban.list | keyvalue: asIsOrder">
                    <li class="list-group-item rounded-0 bg-footer" (click)="bukubesar(val.value.id, 'neraca', 'hutang')" role="button">
                        <span class="font-weight-bold">
                            {{ val.value.nama }}
                        </span>
                    </li>
                    <li class="list-group-item rounded-0" 
						*ngFor="let row of neraca.modelKewajiban.list[val.key].detail | keyvalue: asIsOrder" 
						(click)="bukubesar(row.value.id, 'neraca', 'hutang')" role="button">
                        <div class="d-flex justify-content-between">
                            <span>{{ row.value.kode }}</span>
                            <span class="text-nowrap">
                                {{ row.value.saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ row.value.nama }}</span>
                            <span class="text-nowrap text-white">
                                {{ row.value.saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                    <li class="list-group-item rounded-0 font-weight-bold">
                        <div class="d-flex justify-content-between">
                            <span> Total {{ val.value.nama }} </span>
                            <span class="text-nowrap">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
                <ul class="list-group">
                    <li class="list-group-item rounded-0">
                        <div class="
								d-flex
								justify-content-between
								font-weight-bold
								mb-0
								text-biru
							">
                            <span>Total Kewajiban</span>
                            <span class="text-nowrap {{ neraca.modelKewajiban.total < 0 ? 'text-danger' : 'text-success' }}">
                                {{ neraca.modelKewajiban.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-header font-weight-bold text-uppercase" id="headingThree" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span>Modal</span>
            </div>
            <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample">
                <ul class="list-group" *ngFor="let val of neraca.modelModal.list | keyvalue: asIsOrder">
                    <li class="list-group-item rounded-0 bg-footer font-weight-bold" (click)="bukubesar(val.value.id, 'neraca', 'modal')" role="button">
                        <span>
                            {{ val.value.nama }}
                        </span>
                    </li>
                    <li class="list-group-item rounded-0" *ngFor="let row of neraca.modelModal.list[val.key].detail | keyvalue: asIsOrder" 
						(click)="bukubesar(row.value.id, 'neraca', 'modal')" role="button">
                        <div class="d-flex justify-content-between">
                            <span>{{ row.value.kode }}</span>
                            <span class="text-nowrap">
                                {{ row.value.saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ row.value.nama }}</span>
                            <span class="text-nowrap text-white">
                                {{ row.value.saldo | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                    <li class="list-group-item rounded-0 font-weight-bold">
                        <div class="d-flex justify-content-between">
                            <span> Total {{ val.value.nama }} </span>
                            <span class="text-nowrap">
                                {{ val.value.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
                <ul class="list-group">
                    <li class="list-group-item rounded-0">
                        <div class="d-flex justify-content-between font-weight-bold text-biru">
                            <span> Total Modal </span>
                            <span class="text-nowrap {{ neraca.modelModal.total < 0 ? 'text-danger' : 'text-success' }}">
                                {{ neraca.modelModal.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                    <li class="list-group-item rounded-0 bg-biru">
                        <div class="d-flex justify-content-between font-weight-bold text-white">
                            <span> Total Kewajiban dan Modal </span>
                            <span class="text-nowrap {{ neraca.modelKewajibanModal.total < 0 ? 'text-danger' : '' }}">
                                {{ neraca.modelKewajibanModal.total | currency: "Rp. ":"symbol":"1.0-0" }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="justify-content-between mb-4 text-white responsive-max-width" style="position: fixed; bottom: 50; z-index: 1000;"
    *ngIf="jenisView == 'report' && !isPeriode && !isView && !isOverdue && !isBelumlunas && !isReminderInv && !isUmurPiutang && !isProfit && !isCashflow && !detailCashflow &&
    !isJurnalpenerimaan && !isJurnalpengeluaran && !detailJurnalpenerimaan && !detailJurnalpengeluaran && !isNeraca && !isProfile && !isBukuBesar && !isHideSave">
    <div class="row" style="float: right; max-width: 200px;">
        <div style="border-radius: 50px; background-image: linear-gradient(45deg, #2dc7d9, transparent);"
            class="bg-biru p-2 shadow-top text-center mr-2" (click)="tutupBulan()" role="button" 
            *ngIf="!isLoading && listTutupBulan[thisYear] != undefined && listTutupBulan[thisYear][current + 1] != undefined && listTutupBulan[thisYear][current + 1].is_tampil_save == 1">
            <h6 class="m-0">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" style="fill: white;">
                    <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
                </svg>
                Tutup Bulan
            </h6>
        </div>
        <div style="border-radius: 50px; background-image: linear-gradient(45deg, transparent, #fb3737);"
            class="bg-orange p-2 shadow-top text-center mr-2 mt-2" (click)="hapusTutupBulan()" role="button" 
            *ngIf="!isLoading && listTutupBulan[thisYear] != undefined && listTutupBulan[thisYear][current + 1] != undefined && listTutupBulan[thisYear][current + 1].is_tampil_delete == 1">
            <h6 class="m-0">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" style="fill: #ffffff">
                    <path d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"/>
                </svg>
                Hapus Tutup Bulan
            </h6>
        </div>
        <div style="border-radius: 50px;background-image: linear-gradient(45deg, #c4c6c9, #b7bbbf); zoom: 70%;"
            class="p-2 shadow-top text-center mr-2 mt-2" 
            *ngIf="!isLoading && listTutupBulan[thisYear] != undefined && listTutupBulan[thisYear][current + 1] != undefined && listTutupBulan[thisYear][current + 1].is_tutup_bulan == 1">
            <h6 class="m-0">
                CLOSED by {{listTutupBulan[thisYear][current + 1].created_by}} 
                <br>
                <span style="font-size: 12px;">
                    at {{listTutupBulan[thisYear][current + 1].created_at}}
                </span>
            </h6>
        </div>
    </div>
</div>
<!-- <div style="position: fixed; bottom: 50; width: 576px; max-width: 576px; z-index: 100;" 
    *ngIf="jenisView == 'report' && !isPeriode && !isView && !isOverdue && !isBelumlunas && !isUmurPiutang && !isProfit && !isCashflow && !detailCashflow &&
    !isJurnalpenerimaan && !isJurnalpengeluaran && !detailJurnalpenerimaan && !detailJurnalpengeluaran && !isNeraca && !isProfile && !isBukuBesar && !isHideSave">
    <table class="text-center" style="width: 100%; color: white;">
        <tr>
            <td style="width: 60%"></td>
            <td style="width: 35%;">
                <div style="border-radius: 50px; background-image: linear-gradient(45deg, #2dc7d9, transparent);"
                    class="bg-biru p-2 shadow-top" (click)="tutupBulan()" role="button" 
                    *ngIf="!isLoading && listTutupBulan[thisYear] != undefined && listTutupBulan[thisYear][current + 1] != undefined && listTutupBulan[thisYear][current + 1].is_tampil_save == 1">
                    <h6 class="m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" style="fill: white;">
                            <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
                        </svg>
                        Tutup Bulan
                    </h6>
                </div>
                <div style="border-radius: 50px; background-image: linear-gradient(45deg, transparent, #fb3737);"
                    class="bg-orange p-2 shadow-top mb-1" (click)="hapusTutupBulan()" role="button" 
                    *ngIf="!isLoading && listTutupBulan[thisYear] != undefined && listTutupBulan[thisYear][current + 1] != undefined && listTutupBulan[thisYear][current + 1].is_tampil_delete == 1">
                    <h6 class="m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" style="fill: #ffffff">
                            <path d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"/>
                        </svg>
                        Hapus Tutup Bulan
                    </h6>
                </div>
                <div style="border-radius: 50px; background-image: linear-gradient(45deg, #bdbdbd, #9e9d9d);"
                    class="p-2 shadow-top" *ngIf="!isLoading && listTutupBulan[thisYear] != undefined && listTutupBulan[thisYear][current + 1] != undefined && listTutupBulan[thisYear][current + 1].is_tutup_bulan == 1">
                    <h6 class="m-0">
                        CLOSED by {{listTutupBulan[thisYear][current + 1].created_by}} 
                        <br>
                        <span style="font-size: 12px;">
                            at {{listTutupBulan[thisYear][current + 1].created_at}}
                        </span>
                    </h6>
                </div>
            </td>
            <td style="width: 5%"></td>
        </tr>
    </table>
</div> -->

<!-- footer -->
<app-footer [thisYear]="thisYear" [val]="val" [isLoading]="isLoading" [ngClass]="!isView && !isOverdue && !isBelumlunas && !isReminderInv && !isUmurPiutang && !isProfit && !isCashflow && !detailCashflow &&
            !isJurnalpenerimaan && !isJurnalpengeluaran && !detailJurnalpenerimaan && !detailJurnalpengeluaran && !isNeraca && !isProfile && !isBukuBesar ? '' : 'd-none'"
            (pindahmenu)="pindahMenu($event)">
</app-footer>